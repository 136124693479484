import React from 'react'
import { Route, Redirect, RouteProps } from 'react-router-dom'
import { inject, observer } from 'mobx-react'

import { AuthStore } from '../../stores/AuthStore'

interface IProps extends RouteProps {
  authStore?: AuthStore
}

const PrivateRoute: React.FC<IProps> = ({ authStore, component: Component, ...routeProps }) => {
  return (
    <Route
      {...routeProps}
      render={props =>
        authStore && authStore.loggedIn && Component ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location },
            }}
          />
        )
      }
    />
  )
}

export default inject('authStore')(observer(PrivateRoute))
