import React from 'react'
import { Router, Switch, Route } from 'react-router-dom'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import 'moment/locale/ru'

import PrivateRoute from './components/Routes/PrivateRoute'
import Login from './views/Auth/Login'
import { MainLayout } from './layouts'
import Invite from './views/Auth/Invite'
import { history } from './core/history'

function App() {
  return (
    <div className='App'>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <Router history={history}>
          <Switch>
            <Route path='/login' name='login' component={Login} />
            <Route path='/invite/:token' name='invite' component={Invite} />
            <PrivateRoute path='/' component={MainLayout} />
          </Switch>
        </Router>
      </MuiPickersUtilsProvider>
    </div>
  )
}

export default App
