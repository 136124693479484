import gql from 'graphql-tag'

export const ALL_BUSINESSES_QUERY = gql`
  query AllBusinesses($pagination: PaginationInput, $filter: BusinessesFilter) {
    businessesForFranchisee(pagination: $pagination, filter: $filter) {
      businesses {
        id
        companyName
        cashbackPercent
        transactionFee
        transactionFeeType
        minTransactionFee
        isVisible
        isActivated
        balance
        bonusBalance
        createdAt
        image {
          id
          url
        }
        country {
          id
          name
          numberCode
          code
          currencyCode
          currencySymbol
          minTransactionFee
          registrationPrice
        }
      }
      total
    }
  }
`

export const BUSINESS_DETAIL_QUERY = gql`
  query BusinessDetail($id: Int!) {
    businessDetailForFranchisee(id: $id) {
      id
      companyName
      cashbackPercent
      transactionFee
      transactionFeeType
      minTransactionFee
      isVisible
      isActivated
      balance
      bonusBalance
      bonusBalanceExpiresAt
      address
      website
      phone
      description
      schedule {
        day
        workingTimes
      }
      owner {
        id
        phone
        fullName
      }
      image {
        id
        url
      }
      createdAt
      country {
        id
        name
        numberCode
        code
        currencyCode
        currencySymbol
        minTransactionFee
        registrationPrice
        cities {
          id
          name
        }
      }
      city {
        id
        name
      }
    }
  }
`

export const BUSINESS_STATS_QUERY = gql`
  query BusinessStatsQuery($id: Int!) {
    businessStats(id: $id) {
      transactionsCount
      contractsCount
    }
  }
`

export const BUSINESS_BALANCE_TRANSACTIONS_QUERY = gql`
  query BusinessBalanceTransactionsQuery($businessId: Int!, $pagination: PaginationInput) {
    businessBalanceTransactionsForFranchisee(businessId: $businessId, pagination: $pagination) {
      transactions {
        id
        amount
        direction
        type
        typeComment
        status
        psPaymentId
        paymentDate
        description
        error
        errorCode
        balanceType
        transactionFee
        transactionFeeType
        transaction {
          id
          cashbackPercent
          amount
        }
        executedBy {
          id
          phone
          fullName
        }
        createdAt
        updatedAt
        promocode {
          id
          code
          title
          discount
          discountType
        }
      }
      total
    }
  }
`

export const UPDATE_BUSINESS_PROFILE_MUTATION = gql`
  mutation UpdateBusinessProfileMutation($businessId: Int!, $input: BusinessProfileInput!) {
    updateBusinessProfileByAdmin(businessId: $businessId, input: $input)
  }
`

export const UPDATE_BUSINESS_AVATAR_MUTATION = gql`
  mutation UpdateBusinessAvatarMutation($businessId: Int!, $file: Upload!) {
    updateBusinessAvatarByAdmin(businessId: $businessId, file: $file) {
      id
      url
    }
  }
`

export const ADD_BALANCE_TO_BUSINESS_MUTATION = gql`
  mutation AddBalanceToBusinessMutation($businessId: Int!, $amount: Float!, $balanceType: String!, $paymentType: Int!) {
    addBalanceToBusiness(businessId: $businessId, amount: $amount, balanceType: $balanceType, paymentType: $paymentType)
  }
`

export const WITHDRAW_BUSINESS_BALANCE_MUTATION = gql`
  mutation WithdrawBusinessBalanceMutation($businessId: Int!, $input: WithdrawBusinessBalanceInput!) {
    withdrawBusinessBalance(businessId: $businessId, input: $input)
  }
`

export const UPDATE_BUSINESS_TRANSACTION_FEE_MUTATION = gql`
  mutation UpdateBusinessTransactionFeeMutation($businessId: Int!, $input: UpdateBusinessTransactionFeeInput!) {
    updateBusinessTransactionFee(businessId: $businessId, input: $input)
  }
`

export const SWITCH_BUSINESS_VISIBILITY_MUTATION = gql`
  mutation SwitchBusinessVisibilityMutation($businessId: Int!) {
    switchBusinessVisibility(businessId: $businessId)
  }
`

export const ACTIVATED_BUSINESS_PROMOCODES_QUERY = gql`
  query GetActivatedBusinessPromocodesQuery($businessId: Int!) {
    activatedBusinessPromocodes(businessId: $businessId) {
      id
      title
      code
      discountValue
    }
  }
`
