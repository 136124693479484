import React from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { PageHeader, Content } from '../../../components/Layout'
import {
  Box,
  makeStyles,
  Link,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableFooter,
} from '@material-ui/core'
import { useQuery } from '@apollo/react-hooks'
import classnames from 'classnames'

import { IBusinessShortest } from '../../../graphql/types/businesses'
import { ALL_BUSINESS_TRANSACTION_FEES_BY_DAYS } from '../../../graphql/queries/report'
import { EmptyDataMessage } from '../../../components/Data'
import { Loader } from '../../../components/UI'
import Pagination from '../../../components/Data/pagination/Pagination'
import { ROWS_PER_PAGE } from '../../../core/constants'
import {
  IAllBusinessTransactionFeesByDaysVariables,
  IAllBusinessTransactionFeesByDaysData,
} from '../../../graphql/types/report'
import { useBusinessTransactionFeesByDayFilter } from './useBusinessTransactionFeesByDayFilter'
import { IPagination } from '../../../core/interfaces'
import { DiffValue } from './components/DiffValue'

interface IProps extends RouteComponentProps<{}> {}

const BusinessTransactionFeesByDaysReport: React.FC<IProps> = ({ match }) => {
  const classes = useStyles()
  const { renderFilter, filter, handleChangeFilter } = useBusinessTransactionFeesByDayFilter()
  const [pagination, setPagination] = React.useState<IPagination>({ offset: 0, limit: ROWS_PER_PAGE[0], page: 0 })

  const { data, loading } = useQuery<IAllBusinessTransactionFeesByDaysData, IAllBusinessTransactionFeesByDaysVariables>(
    ALL_BUSINESS_TRANSACTION_FEES_BY_DAYS,
    {
      variables: {
        input: {
          businessId: filter.business?.id,
          isVisible: filter.isVisible || undefined,
        },
        pagination: {
          limit: pagination.limit,
          offset: pagination.offset,
        },
      },
      fetchPolicy: 'network-only',
    }
  )
  const items = data?.allBusinessTransactionFeesByDaysForFranchisee.data || []
  const total = data?.allBusinessTransactionFeesByDaysForFranchisee.total || 0
  const summary = data?.allBusinessTransactionFeesByDaysForFranchisee.summary || []

  // const handleChange = <K extends keyof IFilter>(field: K) => (value: Value<IFilter, K>) => {
  //   setFilter({ ...filter, [field]: value, offset: 0, page: 0 })
  // }

  const handleChangePerPage = (limit: number) => {
    setPagination({ ...pagination, offset: pagination.page * limit, limit })
  }
  const handleChangePage = (page: number) => {
    setPagination({ ...pagination, offset: page * pagination.limit, page })
  }

  return (
    <div>
      <PageHeader title={`Отчет по комиссиям`} right={renderFilter()} />
      {loading && <Loader />}
      {items.length > 0 ? (
        <Content noGutter>
          <Pagination
            rowsPerPage={pagination.limit}
            rowsPerPageOptions={ROWS_PER_PAGE}
            onChangeRowsPerPage={handleChangePerPage}
            onChangePage={handleChangePage}
            page={pagination.page}
            count={total}
          />
          <TableContainer className={classes.container}>
            <Table aria-label='simple table' stickyHeader size='small'>
              <TableHead>
                <TableRow>
                  <TableCell
                    className={classnames(classes.cell, classes.head, classes.stickyHeadCell)}
                    align='center'
                    rowSpan={2}
                  >
                    Партнер
                  </TableCell>
                  <TableCell className={classnames(classes.cell, classes.head)} align='center' colSpan={2}>
                    Прошлая неделя
                    <Box display='flex' justifyContent='space-around'>
                      <Box>Кол-во</Box>
                      <Box>Сумма</Box>
                    </Box>
                  </TableCell>
                  <TableCell className={classnames(classes.cell, classes.head)} align='center' colSpan={2}>
                    Текущая неделя
                    <Box display='flex' justifyContent='space-around'>
                      <Box>Кол-во</Box>
                      <Box>Сумма</Box>
                    </Box>
                  </TableCell>
                  <TableCell className={classnames(classes.cell, classes.head)} align='center' colSpan={2}>
                    7 дней назад
                    <Box display='flex' justifyContent='space-around'>
                      <Box>Кол-во</Box>
                      <Box>Сумма</Box>
                    </Box>
                  </TableCell>
                  <TableCell className={classnames(classes.cell, classes.head)} align='center' colSpan={2}>
                    6 дней назад
                    <Box display='flex' justifyContent='space-around'>
                      <Box>Кол-во</Box>
                      <Box>Сумма</Box>
                    </Box>
                  </TableCell>
                  <TableCell className={classnames(classes.cell, classes.head)} align='center' colSpan={2}>
                    5 дней назад
                    <Box display='flex' justifyContent='space-around'>
                      <Box>Кол-во</Box>
                      <Box>Сумма</Box>
                    </Box>
                  </TableCell>
                  <TableCell className={classnames(classes.cell, classes.head)} align='center' colSpan={2}>
                    4 дня назад
                    <Box display='flex' justifyContent='space-around'>
                      <Box>Кол-во</Box>
                      <Box>Сумма</Box>
                    </Box>
                  </TableCell>
                  <TableCell className={classnames(classes.cell, classes.head)} align='center' colSpan={2}>
                    3 дня назад
                    <Box display='flex' justifyContent='space-around'>
                      <Box>Кол-во</Box>
                      <Box>Сумма</Box>
                    </Box>
                  </TableCell>
                  <TableCell className={classnames(classes.cell, classes.head)} align='center' colSpan={2}>
                    2 дня назад
                    <Box display='flex' justifyContent='space-around'>
                      <Box>Кол-во</Box>
                      <Box>Сумма</Box>
                    </Box>
                  </TableCell>
                  <TableCell className={classnames(classes.cell, classes.head)} align='center' colSpan={2}>
                    1 день назад
                    <Box display='flex' justifyContent='space-around'>
                      <Box>Кол-во</Box>
                      <Box>Сумма</Box>
                    </Box>
                  </TableCell>
                  <TableCell align='center' className={classnames(classes.cell, classes.head)} colSpan={2}>
                    Сегодня
                    <Box display='flex' justifyContent='space-around'>
                      <Box>Кол-во</Box>
                      <Box>Сумма</Box>
                    </Box>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {items.map((item, rowIndex) => (
                  <TableRow className={classes.row} key={`partner-${item.businessId}`}>
                    <TableCell
                      align='left'
                      className={classnames(classes.cell, classes.stickyCol, { [classes.oddColor]: !(rowIndex % 2) })}
                    >
                      <Link
                        href='#'
                        onClick={(event: { preventDefault: () => void }) => {
                          event.preventDefault()
                          const business: IBusinessShortest = {
                            id: item.businessId,
                            balance: 0,
                            companyName: item.companyName,
                          }
                          handleChangeFilter('business')(business)
                        }}
                      >
                        {item.companyName}
                      </Link>
                    </TableCell>
                    {item.data.map((cell, index) => (
                      <React.Fragment key={`partner-${item.businessId}-${index}`}>
                        <TableCell align='right' className={classnames(classes.cell, classes.subCell)}>
                          <Box display='flex' alignItems='center' justifyContent='space-between'>
                            {cell.count.toLocaleString()}
                            <DiffValue current={cell.count} prev={cell.countPrev} />
                          </Box>
                        </TableCell>
                        <TableCell align='right' className={classnames(classes.cell, classes.subCell)}>
                          <Box display='flex' alignItems='center' justifyContent='space-between'>
                            {cell.amount.toLocaleString()}
                            <DiffValue current={cell.amount} prev={cell.amountPrev} isFloat />
                          </Box>
                        </TableCell>
                      </React.Fragment>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
              <TableFooter className={classes.stickyFooter}>
                <TableRow>
                  <TableCell
                    align='left'
                    className={classnames(classes.cell, classes.summary, classes.stickyFooterCol)}
                  >
                    Итого:
                  </TableCell>
                  {summary.map((cell, index) => (
                    <React.Fragment key={`total-${index}`}>
                      <TableCell
                        align='right'
                        className={classnames(classes.cell, classes.summary, classes.subCell, classes.stickyFooter)}
                      >
                        <Box display='flex' alignItems='center' justifyContent='space-between'>
                          {cell.count.toLocaleString()}
                          <DiffValue current={cell.count} prev={cell.countPrev} />
                        </Box>
                      </TableCell>
                      <TableCell
                        align='right'
                        className={classnames(classes.cell, classes.summary, classes.subCell, classes.stickyFooter)}
                      >
                        <Box display='flex' alignItems='center' justifyContent='space-between'>
                          {cell.amount.toLocaleString()}
                          <DiffValue current={cell.amount} prev={cell.amountPrev} isFloat />
                        </Box>
                      </TableCell>
                    </React.Fragment>
                  ))}
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
          <Pagination
            rowsPerPage={pagination.limit}
            rowsPerPageOptions={ROWS_PER_PAGE}
            onChangeRowsPerPage={handleChangePerPage}
            onChangePage={handleChangePage}
            page={pagination.page}
            count={total}
          />
        </Content>
      ) : (
        <EmptyDataMessage />
      )}
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  filterContainer: {
    margin: theme.spacing(1, 0),
  },
  container: {
    maxHeight: 'max(60vh, 100vh - 280px)',
  },
  summary: {
    color: theme.palette.grey[800],
    fontWeight: 600,
  },
  head: {
    color: theme.palette.grey[800],
    fontWeight: 400,
  },
  stickyHeadCell: {
    position: 'sticky',
    width: 160,
    minWidth: 160,
    maxWidth: 160,
    left: 0,
    zIndex: 4,
  },
  stickyFooter: {
    position: 'sticky',
    bottom: 0,
    zIndex: 3,
    backgroundColor: 'white',
  },
  stickyFooterCol: {
    position: 'sticky',
    backgroundColor: 'white',
    width: 160,
    minWidth: 160,
    maxWidth: 160,
    left: 0,
    bottom: 0,
    zIndex: 4,
    borderRight: '1px solid rgba(224, 224, 224, 1)',
  },
  stickyCol: {
    position: 'sticky',
    backgroundColor: 'white',
    width: 160,
    minWidth: 160,
    maxWidth: 160,
    left: 0,
    zIndex: 3,
    borderRight: '1px solid rgba(224, 224, 224, 1)',
  },
  cell: {
    padding: theme.spacing(0.5, 1),
    borderRight: '1px solid rgba(224, 224, 224, 1)',
  },
  subCell: {
    minWidth: 90,
  },
  oddColor: {
    backgroundColor: '#F5F5F5',
  },
  row: {
    '&:nth-of-type(odd)': {
      backgroundColor: '#F5F5F5',
    },
  },
}))

export default BusinessTransactionFeesByDaysReport
