import React from 'react'
import { Box, IconButton } from '@material-ui/core'
import moment from 'moment'

import { DATE_FORMAT } from '../../core/constants'
import { IPromocode } from '../../graphql/types/promocodes'
import { useAllPromocodes } from '../../graphql/hooks/promocodes'
import { PageHeader, Content } from '../../components/Layout'
import { EmptyDataMessage, DataList } from '../../components/Data'
import { CreateFab, Loader } from '../../components/UI'
import { AddPromocodeModal } from './components/AddPromocodeModal'
import { Edit as EditIcon, Lock as LockIcon } from '@material-ui/icons'
import { EditPromocodeModal } from './components/EditPromocodeModal'

const Promocodes: React.FC = () => {
  const [addModalVisible, setAddModalVisible] = React.useState<boolean>(false)
  const [editPromocode, setEditPromocode] = React.useState<IPromocode | null>(null)
  const { promocodes, loading } = useAllPromocodes()

  const toggleModal = () => {
    setAddModalVisible(!addModalVisible)
  }

  const renderTitle = (item: IPromocode) => {
    return `${item.title} - ${item.code}`
  }

  const renderSecondaryText = (item: IPromocode) => {
    return `${moment(item.startDate).format(DATE_FORMAT)} - ${moment(item.endDate).format(DATE_FORMAT)}`
  }

  const resetEditPromocode = () => {
    setEditPromocode(null)
  }

  const handleEdit = (item: IPromocode) => () => {
    setEditPromocode(item)
  }

  const renderActions = (item: IPromocode) => {
    if (item.deactivated) {
      return (
        <Box display='inline-flex' alignItems='center' color='grey.400'>
          <Box mr={1}>Промокод деактивирован</Box>
          <LockIcon />
        </Box>
      )
    }
    return (
      <IconButton onClick={handleEdit(item)}>
        <EditIcon />
      </IconButton>
    )
  }

  return (
    <div>
      <PageHeader title='Промокоды' />
      <Box>
        {loading && <Loader />}
        {promocodes.length === 0 && !loading ? (
          <EmptyDataMessage />
        ) : (
          <Content noGutter>
            <DataList
              data={promocodes}
              title={renderTitle}
              renderActions={renderActions}
              secondaryText={renderSecondaryText}
            />
          </Content>
        )}
      </Box>
      <CreateFab onClick={toggleModal} />
      {addModalVisible && <AddPromocodeModal open={addModalVisible} onCancel={toggleModal} />}
      {editPromocode && (
        <EditPromocodeModal open={!!editPromocode} promocode={editPromocode} onCancel={resetEditPromocode} />
      )}
    </div>
  )
}

export default Promocodes
