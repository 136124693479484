import React from 'react'
import { TextField, TextFieldProps } from '@material-ui/core'
import InputMask from 'react-input-mask'

import { PHONE_MASK_DEFAULT } from '../../core/constants'

export const PhoneField: React.FC<TextFieldProps> = ({ value, onChange, ...props }) => {
  const maskValue = typeof value === 'string' ? value : ''

  return (
    <InputMask mask={PHONE_MASK_DEFAULT} value={maskValue} onChange={onChange}>
      {(inputProps: object) => <TextField {...props} {...inputProps} type='tel' />}
    </InputMask>
  )
}
