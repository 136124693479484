import React from 'react'
import { IconButton, Menu, MenuItem } from '@material-ui/core'
import { Person as AccountIcon } from '@material-ui/icons'
import { Link } from 'react-router-dom'

export const HeaderMenu: React.FC = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <div>
      <IconButton color='inherit' onClick={handleClick}>
        <AccountIcon />
      </IconButton>
      <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem onClick={handleClose} component={Link} to='/profile'>
          Профиль
        </MenuItem>
        <MenuItem component={Link} to='/logout'>
          Выйти
        </MenuItem>
      </Menu>
    </div>
  )
}
