import { useQuery } from '@apollo/react-hooks'

import { ALL_PROMOCODES_QUERY } from '../../queries/promocodes'
import { IAllPromocodesData, IAllPromocodesVariables } from '../../types/promocodes'

export const useAllPromocodes = () => {
  const { data, loading } = useQuery<IAllPromocodesData, IAllPromocodesVariables>(ALL_PROMOCODES_QUERY)

  // TODO: pagination

  return {
    promocodes: data?.allPromocodesForFranchisee || [],
    loading,
  }
}
