import React from 'react'
import { Box, Typography } from '@material-ui/core'
import { CancelPresentation as EmptyIcon } from '@material-ui/icons'
import { makeStyles } from '@material-ui/core/styles'

interface IProps {
  message?: string
}

export const EmptyDataMessage: React.FC<IProps> = ({ message = 'Записей не найдено' }) => {
  const classes = useStyles()

  return (
    <Box p={3} textAlign='center'>
      <EmptyIcon className={classes.icon} />
      <Typography className={classes.text}>{message}</Typography>
    </Box>
  )
}

const useStyles = makeStyles(theme => ({
  icon: {
    fontSize: 45,
    color: theme.palette.grey[300],
    marginBottom: theme.spacing(1),
  },
  text: {
    color: theme.palette.grey[400],
  },
}))
