import React from 'react'
import { Box, Dialog, DialogTitle, DialogContent } from '@material-ui/core'
import { Alert } from '@material-ui/lab'

import {
  IPromocode,
  IUpdatePromocodeData,
  IUpdatePromocodeInput,
  IUpdatePromocodeVariables,
} from '../../../graphql/types/promocodes'
import { PromocodeForm, IPromocodeFormValues } from './PromocodeForm'
import { useMutation } from '@apollo/react-hooks'
import { ALL_PROMOCODES_QUERY, UPDATE_PROMOCODE_MUTATION } from '../../../graphql/queries/promocodes'

interface IProps {
  open: boolean
  promocode: IPromocode
  onCancel: () => void
}

export const EditPromocodeModal: React.FC<IProps> = ({ open, promocode, onCancel }) => {
  const [error, setError] = React.useState<string | undefined>(undefined)
  const [updatePromocode, { loading }] = useMutation<IUpdatePromocodeData, IUpdatePromocodeVariables>(
    UPDATE_PROMOCODE_MUTATION, { refetchQueries: [{ query: ALL_PROMOCODES_QUERY }] }
  )

  const handleSubmit = async (values: IPromocodeFormValues) => {
    const input: IUpdatePromocodeInput = {
      id: promocode.id,
      title: values.title,
      description: values.description,
      discount: parseFloat(values.discount),
      discountType: values.discountType,
      startDate: values.startDate,
      endDate: values.endDate,
    }
    try {
      await updatePromocode({ variables: { input } })
    } catch (error) {
      setError(error)
    }

    if (error) {
      setError(error)
    } else {
      onCancel()
    }
  }

  return (
    <Dialog open={open} maxWidth='sm' fullWidth>
      <DialogTitle id='form-dialog-title'>Изменить промокод</DialogTitle>
      <DialogContent>
        {!!error && (
          <Box mb={3}>
            <Alert severity='error'>{error}</Alert>
          </Box>
        )}
        <Box>
          <PromocodeForm loading={loading} promocode={promocode} onSubmit={handleSubmit} onCancel={onCancel} />
        </Box>
      </DialogContent>
    </Dialog>
  )
}
