import React from 'react'
import { Switch, Route, RouteComponentProps } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'

import { Header, AppDrawer } from '../components/Layout'
import PageNotFound from '../views/PageNotFound'
import drawerItems from '../routes/drawer'
import routes from '../routes/private'

import { ProfileWatcher } from '../views/Profile/components/ProfileWatcher'

export const MainLayout: React.FC<RouteComponentProps> = ({ location }) => {
  React.useEffect(() => {
    window.scrollTo(0, 0)
    setDrawerOpen(false)
  }, [location.pathname])

  const classes = useStyles()
  const [drawerOpen, setDrawerOpen] = React.useState<boolean>(false)

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen)
  }

  return (
    <div className={classes.root}>
      <ProfileWatcher />
      <Header drawerOpen={drawerOpen} onClickMenu={toggleDrawer} />
      <AppDrawer routes={drawerItems} open={drawerOpen} onClose={toggleDrawer} />
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Switch>
          {routes.map((route) => {
            return <Route key={`route-${route.name}`} exact {...route} />
          })}
          <Route component={PageNotFound} />
        </Switch>
      </main>
    </div>
  )
}

const useStyles = makeStyles(
  (theme) => ({
    root: {
      display: 'flex',
    },
    content: {
      minWidth: 370,
      flexGrow: 1,
      padding: theme.spacing(3),
      height: '100vh',
      overflow: 'auto',
      // backgroundColor: theme.palette.grey[100],
    },
    appBarSpacer: theme.mixins.toolbar,
  }),
  { name: 'MainLayout' }
)
