import * as yup from 'yup'

import { PHONE_REGEXP } from '../../../../core/constants'

export const supervisorSchema = yup.object().shape({
  firstName: yup.string().required(),
  phone: yup
    .string()
    .trim()
    .matches(PHONE_REGEXP, 'Не правильный формат телефона')
    .required(),
  email: yup
    .string()
    .email()
    .required(),
})
