import React, { Fragment } from 'react'
import { Box, Divider, Typography } from '@material-ui/core'

import { Content } from '../../../components/Layout'
import { useFranchiseeBalanceTransactions } from '../../../graphql/hooks/franchisee'
import { Loader } from '../../../components/UI'
import { DataTable, EmptyDataMessage, IDataTableColumn } from '../../../components/Data'
import { IFranchisee, IFranchiseeTransaction } from '../../../graphql/types/franchisee'
import { AmountColumn, BalanceTransactionStatus, CreatedAtColumn, TypeColumn, BusinessColumn } from '../columns'

interface IProps {
  franchisee: IFranchisee
}

const columns: IDataTableColumn<IFranchiseeTransaction>[] = [
  { label: 'ID', accessor: 'id' },
  //TODO: Currency
  { label: 'Сумма', accessor: (item) => <AmountColumn item={item} currency={'₸'} /> },
  { label: 'Статус', accessor: (item) => <BalanceTransactionStatus item={item} /> },
  {
    label: 'Тип',
    accessor: (item) => <TypeColumn item={item} />,
  },
  {
    label: 'Бизнес',
    //TODO: Currency
    accessor: (item) => <BusinessColumn item={item} currency={'₸'} />,
  },
  {
    label: 'Дата',
    accessor: (item) => <CreatedAtColumn item={item} />,
  },
]

export const BalanceHistory: React.FC<IProps> = ({ franchisee }) => {
  const { transactions, total, loading } = useFranchiseeBalanceTransactions()

  return (
    <Content noGutter>
      <Box p={2} display='flex' alignItems='center' justifyContent='space-between'>
        <Typography>Всего: {total}</Typography>
        <Typography variant='h6'>{franchisee.balance}₸</Typography>
      </Box>
      <Divider />
      {loading ? (
        <Loader />
      ) : (
        <Fragment>
          {transactions.length > 0 && !loading ? (
            <DataTable data={transactions} columns={columns} />
          ) : (
            <EmptyDataMessage />
          )}
        </Fragment>
      )}
    </Content>
  )
}
