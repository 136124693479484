import React from 'react'
import {
  Button,
  TextField,
  Box,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@material-ui/core'
import { Formik, FormikProps } from 'formik'
import * as yup from 'yup'
import { useMutation } from '@apollo/react-hooks'
import { useSnackbar } from 'notistack'

import {
  WITHDRAW_BUSINESS_BALANCE_MUTATION,
  BUSINESS_BALANCE_TRANSACTIONS_QUERY,
  BUSINESS_DETAIL_QUERY,
} from '../../../../graphql/queries/businesses'
import {
  IBusiness,
  IWithdrawBusinessBalanceData,
  IWithdrawBusinessBalanceInput,
  IWithdrawBusinessBalanceVariables,
} from '../../../../graphql/types/businesses'
import { LoadableButton } from '../../../../components/Form'
import { BusinessBalanceType, BusinessTransactionType } from '../../../../core/interfaces'
import { BUSINESS_TRANSACTION_TYPE_LABELS, LIST_LIMIT } from '../../../../core/constants'

interface IProps {
  business: IBusiness
  onClose: () => void
}

interface IWithdrawBalanceFormValues {
  balanceType: BusinessBalanceType
  amount: string
  type: BusinessTransactionType
  typeComment: string
}

const validationSchema = yup.object().shape({
  amount: yup
    .number()
    .moreThan(0)
    .required(),
  balanceType: yup
    .string()
    .oneOf([BusinessBalanceType.DEFAULT, BusinessBalanceType.BONUS])
    .required(),
  type: yup
    .number()
    .integer()
    .required(),
  typeComment: yup.string().when('type', {
    is: BusinessTransactionType.OTHER,
    then: yup.string().required(),
    otherwise: yup.string().nullable(),
  }),
})

export const WithdrawBalanceForm: React.FC<IProps> = ({ business, onClose }) => {
  const initialValues: IWithdrawBalanceFormValues = {
    balanceType: BusinessBalanceType.DEFAULT,
    amount: '',
    type: BusinessTransactionType.REGISTRATION_FEE,
    typeComment: '',
  }

  const { enqueueSnackbar } = useSnackbar()
  const [loading, setLoading] = React.useState<boolean>(false)
  const [withdrawBusinessBalance] = useMutation<IWithdrawBusinessBalanceData, IWithdrawBusinessBalanceVariables>(
    WITHDRAW_BUSINESS_BALANCE_MUTATION
  )

  const handleSubmit = async (values: IWithdrawBalanceFormValues) => {
    const input: IWithdrawBusinessBalanceInput = {
      amount: parseFloat(values.amount),
      balanceType: values.balanceType,
      type: values.type,
      typeComment: values.typeComment || undefined,
    }

    try {
      setLoading(true)

      const { data } = await withdrawBusinessBalance({
        variables: {
          businessId: business.id,
          input,
        },
        refetchQueries: [
          {
            query: BUSINESS_BALANCE_TRANSACTIONS_QUERY,
            variables: { businessId: business.id, pagination: { offset: 0, limit: LIST_LIMIT } },
          },
          { query: BUSINESS_DETAIL_QUERY, variables: { id: business.id } },
        ],
      })

      if (data?.withdrawBusinessBalance) {
        enqueueSnackbar('Списание Баланса прошло успешно!', { variant: 'success' })
        onClose()
      }
    } catch (error) {
      enqueueSnackbar('Ошибка при списании', { variant: 'error' })
      console.log(error.message)
      setLoading(false)
    }
  }

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
      {({ values, errors, handleChange, submitForm }: FormikProps<IWithdrawBalanceFormValues>) => (
        <div>
          <Box py={2}>
            <FormControl component='fieldset'>
              <FormLabel component='legend'>Выберите Баланс</FormLabel>
              <RadioGroup aria-label='gender' name='balanceType' value={values.balanceType} onChange={handleChange}>
                <FormControlLabel value={BusinessBalanceType.DEFAULT} control={<Radio />} label='Основной Баланс' />
                <FormControlLabel value={BusinessBalanceType.BONUS} control={<Radio />} label='Подарочный Баланс' />
              </RadioGroup>
            </FormControl>
          </Box>
          <Box paddingY={2}>
            <Box mb={2}>
              <FormControl fullWidth margin='normal'>
                <InputLabel>Тип</InputLabel>
                <Select label='Тип' name='type' value={values.type} fullWidth onChange={handleChange}>
                  <MenuItem value={BusinessTransactionType.REGISTRATION_FEE}>
                    {BUSINESS_TRANSACTION_TYPE_LABELS[BusinessTransactionType.REGISTRATION_FEE]}
                  </MenuItem>
                  <MenuItem value={BusinessTransactionType.OTHER}>
                    {BUSINESS_TRANSACTION_TYPE_LABELS[BusinessTransactionType.OTHER]}
                  </MenuItem>
                </Select>
              </FormControl>
            </Box>
            {values.type === BusinessTransactionType.OTHER && (
              <Box mb={2}>
                <TextField
                  placeholder='Комментарий'
                  label='Комментарий'
                  value={values.typeComment}
                  onChange={handleChange('typeComment')}
                  multiline
                  error={!!errors.typeComment}
                  margin='normal'
                  fullWidth
                />
              </Box>
            )}
            <TextField
              label='Сумма списания'
              name='amount'
              value={values.amount}
              onChange={handleChange}
              required
              fullWidth
              margin='normal'
              error={!!errors.amount}
              autoFocus
              type='number'
            />
          </Box>
          <Box mt={2} display='flex' flexDirection='row' justifyContent='space-between'>
            <Button onClick={onClose} color='default'>
              Закрыть
            </Button>
            <LoadableButton loading={loading} variant='contained' color='primary' onClick={submitForm}>
              Применить
            </LoadableButton>
          </Box>
        </div>
      )}
    </Formik>
  )
}
