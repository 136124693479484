import React from 'react'
import { Box, Button, TextField, Typography } from '@material-ui/core'
import { Formik, FormikProps } from 'formik'

import { ROLES_LABELS } from '../../../../core/constants'
import { PhoneField, LoadableButton } from '../../../../components/Form'
import { UserRole } from '../../../../core/interfaces'
import { supervisorSchema } from './validation'

interface IProps {
  loading: boolean
  onSubmit: (values: ISupervisorFormValues) => void
  onCancel: () => void
}

export interface ISupervisorFormValues {
  firstName: string
  phone: string
  email: string
}

export const SupervisorForm: React.FC<IProps> = ({ loading, onSubmit, onCancel }) => {
  const initialValues: ISupervisorFormValues = {
    firstName: '',
    phone: '',
    email: '',
  }

  const handleSubmit = (values: ISupervisorFormValues) => {
    onSubmit(values)
  }

  return (
    <Formik initialValues={initialValues} validationSchema={supervisorSchema} onSubmit={handleSubmit}>
      {({ values, errors, setFieldValue, handleChange, submitForm }: FormikProps<ISupervisorFormValues>) => (
        <div>
          <Box mb={1}>
            <Typography variant='body2' color='textSecondary'>
              Роль
            </Typography>
            <Typography>{ROLES_LABELS[UserRole.SALES_SUPERVISOR]}</Typography>
          </Box>
          <TextField
            label='Имя'
            name='firstName'
            value={values.firstName}
            onChange={handleChange}
            required
            fullWidth
            margin='normal'
            error={!!errors.firstName}
          />
          <PhoneField
            label='Телефон'
            name='phone'
            value={values.phone}
            onChange={e => setFieldValue('phone', `+${e.target.value.replace(/\D+/g, '')}`)}
            error={!!errors.phone}
            required
            fullWidth
            margin='normal'
          />
          <TextField
            label='Email'
            name='email'
            value={values.email}
            onChange={handleChange}
            required
            fullWidth
            margin='normal'
            error={!!errors.email}
          />
          <Box pt={4} pb={1} display='flex' flexDirection='row' justifyContent='space-between'>
            <Button onClick={onCancel}>Отмена</Button>
            <LoadableButton loading={loading} color='primary' variant='contained' onClick={submitForm}>
              Сохранить
            </LoadableButton>
          </Box>
        </div>
      )}
    </Formik>
  )
}
