import React from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { Grid, Box } from '@material-ui/core'
import {
  SwapVert as TransactionsIcon,
  People as CustomersIcon,
  AccountBalanceWallet as BalanceIcon,
  LocalAtm as ComissionIcon,
  Edit as EditIcon,
} from '@material-ui/icons'

import { useBusinessDetailWithStatsQuery } from '../../graphql/hooks/businesses'
import { PageHeader, Content } from '../../components/Layout'
import { Loader } from '../../components/UI'
import { SingleValueWidget } from '../../components/Widgets'
import PageNotFound from '../PageNotFound'
import { BusinessInfo, TransactionFeeModal, BusinessVisibility } from './components'
import { TransactionFeeType } from '../../core/interfaces'

interface IProps extends RouteComponentProps<{ id: string }> {}

const BusinessDetail: React.FC<IProps> = ({ history, match }) => {
  const [transactionFeeModalVisble, setTransactionFeeModalVisible] = React.useState<boolean>(false)
  const { business, stats, loading } = useBusinessDetailWithStatsQuery(parseInt(match.params.id, 10))

  if (loading) {
    return <Loader />
  }

  if (!(business && stats)) {
    return <PageNotFound />
  }

  const toggleTransactionFeeModal = () => {
    setTransactionFeeModalVisible(!transactionFeeModalVisble)
  }

  const navigateToBalance = () => {
    history.push(`/businesses/${business.id}/balance`)
  }

  const transactionFeeValue = () => {
    const suffix = business.transactionFeeType === TransactionFeeType.FIX ? ` ${business.country.currencySymbol}` : '%'

    return `${business.transactionFee}${suffix}`
  }

  const minTransactionFeeValue = () => {
    return business.minTransactionFee
      ? `Мин ${business.minTransactionFee}${business.country.currencySymbol}`
      : undefined
  }

  return (
    <div>
      <PageHeader title={business.companyName} back />
      <Box mb={3} display='flex' alignItems='center' justifyContent='space-between'>
        <BusinessVisibility business={business} />
      </Box>
      <Box mb={3}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={3}>
            <SingleValueWidget
              label='Транзакции'
              value={`${stats.transactionsCount}`}
              fullHeight
              Icon={TransactionsIcon}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <SingleValueWidget label='Клиенты' value={`${stats.contractsCount}`} fullHeight Icon={CustomersIcon} />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <SingleValueWidget
              label='Баланс'
              value={`${business.balance} ${business.country.currencySymbol}`}
              fullHeight
              Icon={BalanceIcon}
              onClick={navigateToBalance}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <SingleValueWidget
              label='Комиссия'
              value={transactionFeeValue()}
              valueDesc={minTransactionFeeValue()}
              fullHeight
              Icon={ComissionIcon}
              onClick={toggleTransactionFeeModal}
              OnClickIcon={EditIcon}
            />
          </Grid>
        </Grid>
      </Box>
      <Content>
        <BusinessInfo business={business} />
      </Content>
      <TransactionFeeModal open={transactionFeeModalVisble} business={business} onClose={toggleTransactionFeeModal} />
    </div>
  )
}

export default BusinessDetail
