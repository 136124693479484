import * as yup from 'yup'

import { PHONE_REGEXP } from '../../../core/constants'

export const profileSchema = yup.object().shape({
  companyName: yup.string().required('Обязательное поле'),
  cashbackPercent: yup
    .number()
    .moreThan(0, 'Размер кешбека должен быть больше 0')
    .max(100, 'Размер кешбека не может превышать 100%')
    .required('Обязательное поле'),
  address: yup.string().nullable(),
  phone: yup
    .string()
    .trim()
    .matches(PHONE_REGEXP, 'Не правильный формат телефона')
    .nullable(),
  website: yup.string().nullable(),
  description: yup.string().nullable(),
  schedule: yup
    .array(
      yup.object().shape({
        day: yup
          .number()
          .integer()
          .required(),
        workingTimes: yup.string().required(),
      })
    )
    .nullable(),
})
