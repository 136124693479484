import React from 'react'
import { useMutation } from '@apollo/react-hooks'

import { CREATE_SALES_MANAGER_MUTATION, SALES_MANAGERS_QUERY } from '../../queries/users'
import { ISalesManagerInput, ICreateSalesManagerData, ICreateSalesManagerVariables } from '../../types/user'

export const useCreateSalesManager = () => {
  const [loading, setLoading] = React.useState<boolean>(false)
  const [createSalesManagerMutation] = useMutation<ICreateSalesManagerData, ICreateSalesManagerVariables>(
    CREATE_SALES_MANAGER_MUTATION
  )

  const createSalesManager = async (input: ISalesManagerInput): Promise<{ error?: string }> => {
    try {
      setLoading(true)

      await createSalesManagerMutation({
        variables: { input },
        refetchQueries: [
          {
            query: SALES_MANAGERS_QUERY,
          },
        ],
      })

      return {}
    } catch (error) {
      return { error: error.message }
    } finally {
      setLoading(false)
    }
  }

  return {
    createSalesManager,
    loading,
  }
}
