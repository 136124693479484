import { useQuery } from '@apollo/react-hooks'

import { FRANCHISEE_BALANCE_TRANSACTIONS_QUERY } from '../../queries/franchisee'
import { IFranchiseeBalanceTransactionsData, IFranchiseeBalanceTransactionsVariables } from '../../types/franchisee'

export const useFranchiseeBalanceTransactions = () => {
  const { data, ...query } = useQuery<IFranchiseeBalanceTransactionsData, IFranchiseeBalanceTransactionsVariables>(
    FRANCHISEE_BALANCE_TRANSACTIONS_QUERY,
    {
      variables: {},
    }
  )

  return {
    transactions: data?.franchiseeBalanceTransactions.transactions || [],
    total: data?.franchiseeBalanceTransactions.total || 0,
    ...query,
  }
}
