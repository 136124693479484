import React from 'react'
import { Avatar, makeStyles } from '@material-ui/core'
import { Storefront as BusinessIcon } from '@material-ui/icons'

import { IBusiness } from '../../../graphql/types/businesses'

interface IProps {
  business: IBusiness
}

export const AvatarColumn: React.FC<IProps> = ({ business }) => {
  const classes = useStyles()

  return (
    <Avatar src={business.image?.url || undefined} className={classes.avatar}>
      <BusinessIcon />
    </Avatar>
  )
}

const useStyles = makeStyles(theme => ({
  avatar: {
    color: '#fff',
    backgroundColor: theme.palette.primary.main,
  },
}))