import { observable, computed, action } from 'mobx'
import { intersection } from 'lodash'

import { IUser } from '../graphql/types/user'
import { UserRole } from '../core/interfaces'
import { IFranchisee } from '../graphql/types/franchisee'

export class AuthStore {
  @observable user: IUser | null = null
  @observable franchisee: IFranchisee | null = null

  token: string | null = null
  watcher?: ZenObservable.Subscription

  constructor() {
    if (typeof window.localStorage === 'undefined') {
      console.error('Local storage must be enabled.')
    }

    this.readFromLocalStorage()
  }

  @computed
  get loggedIn() {
    return !!this.user && !!this.token
  }

  getToken() {
    return this.token
  }

  getRefreshToken() {
    return window.localStorage.getItem('_token')
  }

  @action
  setFranchisee(franchisee: IFranchisee) {
    this.franchisee = franchisee
  }

  userHasRoles(roles: UserRole[]) {
    const userRoles = (this.user && this.user.roles && this.user.roles) || []
    const hasRoles = intersection(roles, userRoles)

    if (userRoles.includes(UserRole.FRANCHISEE)) {
      return true
    }

    return hasRoles.length > 0
  }

  @action
  async login(user: IUser, token: string, refreshToken: string) {
    this.user = user
    this.token = token
    window.localStorage.setItem('@auth', JSON.stringify({ ...user, token }))
    window.localStorage.setItem('_token', refreshToken)
  }

  @action
  async setUser(user: IUser) {
    this.user = user
    window.localStorage.setItem('@auth', JSON.stringify({ ...user, token: this.token }))
  }

  @action
  logout() {
    this.user = null
    this.token = null

    window.localStorage.removeItem('@auth')
    window.localStorage.removeItem('_token')
  }

  @action
  readFromLocalStorage() {
    const auth = window.localStorage.getItem('@auth')
    if (auth) {
      const { token, ...user } = JSON.parse(auth)
      this.user = user
      this.token = token
    }
  }
}

export default new AuthStore()
