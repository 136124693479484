import React from 'react'
import { Box, Grid, makeStyles, Typography } from '@material-ui/core'
import { AccountBalanceWallet as BalanceIcon } from '@material-ui/icons'
import { useHistory } from 'react-router'

import { Content } from '../../../components/Layout'
import { maskPhone } from '../../../utils/helpers'
import { useProfile } from '../../../graphql/hooks/franchisee'
import { SingleValueWidget } from '../../../components/Widgets'

export const FranchiseeInfo: React.FC = () => {
  const classes = useStyles()
  const history = useHistory()
  const { user, franchisee } = useProfile()

  if (!franchisee || !user) {
    return null
  }

  const navigateToBalance = () => {
    history.push('/balance')
  }

  return (
    <Box mb={3}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={8} lg={9} className={classes.gridItem1}>
          <Content noGutter>
            <Box p={2}>
              <Box mb={2}>
                <Typography variant='h5'>{franchisee.name}</Typography>
              </Box>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  {!!user.fullName && <Typography>{user.fullName}</Typography>}
                  <Typography>{maskPhone(user.phone)}</Typography>
                  <Typography>{franchisee.email}</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Box className={classes.locationBox}>
                    <Typography color='textSecondary'>{franchisee.city.country.name}</Typography>
                    <Typography color='textSecondary'>{franchisee.city.name}</Typography>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Content>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} className={classes.gridItem2}>
          <SingleValueWidget
            label='Баланс'
            value={`${franchisee.balance} ${franchisee.city.country.currencySymbol}`}
            inversed
            fullHeight
            Icon={BalanceIcon}
            onClick={navigateToBalance}
          />
        </Grid>
      </Grid>
    </Box>
  )
}

const useStyles = makeStyles((theme) => ({
  locationBox: {
    [theme.breakpoints.up('sm')]: {
      textAlign: 'right',
    },
  },
  gridItem1: {
    order: 2,
    [theme.breakpoints.up('sm')]: {
      order: 1,
    },
  },
  gridItem2: {
    order: 1,
    [theme.breakpoints.up('sm')]: {
      order: 2,
    },
  },
}))
