import React from 'react'
import { Box } from '@material-ui/core'

import { FranchiseeInfo } from './components'

const Home: React.FC = () => {
  return (
    <Box>
      <FranchiseeInfo />
    </Box>
  )
}

export default Home
