import React from 'react'
import { Box, Dialog, DialogTitle, DialogContent } from '@material-ui/core'
import { Alert } from '@material-ui/lab'

import { useCreateSalesSupervisor } from '../../../../graphql/hooks/users'
import { ISalesSupervisorInput } from '../../../../graphql/types/user'
import { SupervisorForm, ISupervisorFormValues } from './SupervisorForm'

interface IProps {
  open: boolean
  onCancel: () => void
}

export const AddSupervisorModal: React.FC<IProps> = ({ open, onCancel }) => {
  const { createSalesSupervisor, loading, error } = useCreateSalesSupervisor()

  const handleSubmit = async (values: ISupervisorFormValues) => {
    const input: ISalesSupervisorInput = {
      firstName: values.firstName,
      phone: values.phone,
      email: values.email,
    }

    await createSalesSupervisor(input)
    onCancel()
  }

  return (
    <Dialog open={open} maxWidth='sm' fullWidth onClose={onCancel}>
      <DialogTitle id='form-dialog-title'>Новый пользователь</DialogTitle>
      <DialogContent>
        {!!error && (
          <Box mb={3}>
            <Alert severity='error'>{error}</Alert>
          </Box>
        )}
        <Box>
          <SupervisorForm loading={loading} onSubmit={handleSubmit} onCancel={onCancel} />
        </Box>
      </DialogContent>
    </Dialog>
  )
}
