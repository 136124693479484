import React, { Fragment } from 'react'
import { useQuery } from '@apollo/react-hooks'
import { RouteComponentProps } from 'react-router-dom'
import { Button, Box, Typography } from '@material-ui/core'

import { BUSINESS_DETAIL_QUERY } from '../../graphql/queries/businesses'
import { IBusinessDetailData, IBusinessDetailVariables, IBusinessTransaction } from '../../graphql/types/businesses'
import { useBusinessBalanceTransactionsQuery } from '../../graphql/hooks/businesses'
import { PageHeader, Content } from '../../components/Layout'
import { Loader } from '../../components/UI'
import { DataTable, IDataTableColumn, FetchMoreButton, EmptyDataMessage } from '../../components/Data'
import PageNotFound from '../PageNotFound'
import { BusinessBalanceModal } from './components'
import {
  CreatedAtColumn,
  AmountColumn,
  TypeColumn,
  BalanceTypeColumn,
  BalanceTransactionStatus,
  PromocodeColumn,
} from './balanceTransactionsColumns'

interface IProps extends RouteComponentProps<{ id: string }> {}

const BusinessBalanceTransactions: React.FC<IProps> = ({ match }) => {
  const businessId = parseInt(match.params.id, 10)
  const [balanceModalVisible, setBalanceModalVisible] = React.useState<boolean>(false)

  const businessDetailResult = useQuery<IBusinessDetailData, IBusinessDetailVariables>(BUSINESS_DETAIL_QUERY, {
    variables: { id: businessId },
  })
  const { transactions, total, loading, loadingMore, fetchMore } = useBusinessBalanceTransactionsQuery(businessId)
  const business = businessDetailResult.data?.businessDetailForFranchisee

  if (businessDetailResult.loading) {
    return <Loader />
  }

  if (!business) {
    return <PageNotFound />
  }

  const columns: IDataTableColumn<IBusinessTransaction>[] = [
    { label: 'ID', accessor: 'id' },
    { label: '', accessor: (item) => <BalanceTypeColumn item={item} /> },
    { label: 'Сумма', accessor: (item) => <AmountColumn item={item} currency={business.country.currencySymbol} /> },
    { label: 'Статус', accessor: (item) => <BalanceTransactionStatus item={item} /> },
    {
      label: 'Тип',
      accessor: (item) => <TypeColumn item={item} />,
    },
    { label: 'Промокод', accessor: (item) => <PromocodeColumn item={item} /> },
    {
      label: 'Дата',
      accessor: (item) => <CreatedAtColumn item={item} />,
    },
  ]

  const renderRightActions = () => (
    <Button color='primary' variant='outlined' onClick={toggleBalanceModal}>
      Баланс: {`${business.balance} ${business.country.currencySymbol} `}
      {!!business.bonusBalance && <Box component='span'> ({business.bonusBalance})</Box>}
    </Button>
  )

  const toggleBalanceModal = () => {
    setBalanceModalVisible(!balanceModalVisible)
  }

  return (
    <div>
      <PageHeader title={`${business.companyName} (Баланс)`} back right={renderRightActions()} />
      <Box mb={3}>
        <Typography>Всего: {`${total}`}</Typography>
      </Box>
      {loading && <Loader />}
      {transactions.length > 0 ? (
        <Fragment>
          <Content noGutter>
            <DataTable data={transactions} columns={columns} />
          </Content>
          <Box mt={3}>
            <FetchMoreButton loading={loadingMore} count={transactions.length} total={total} onClick={fetchMore} />
          </Box>
        </Fragment>
      ) : (
        <EmptyDataMessage />
      )}
      <BusinessBalanceModal business={business} open={balanceModalVisible} onClose={toggleBalanceModal} />
    </div>
  )
}

export default BusinessBalanceTransactions
