import React from 'react'
import { Typography } from '@material-ui/core'

import { FRANCHISEE_TRANSACTION_TYPE_LABELS } from '../../../core/constants'
import { FranchiseeTransactionType, FranchiseeReplenishmentType } from '../../../core/interfaces'
import { IFranchiseeTransaction } from '../../../graphql/types/franchisee'

interface IProps {
  item: IFranchiseeTransaction
}

const REPLENISHMENT_TYPES = {
  [FranchiseeReplenishmentType.CASH]: ' (наличными)',
  [FranchiseeReplenishmentType.CASHLESS]: ' (безналичными)',
  [FranchiseeReplenishmentType.ONLINE]: ' (онлайн)',
}

export const TypeColumn: React.FC<IProps> = ({ item }) => {
  const value = FRANCHISEE_TRANSACTION_TYPE_LABELS[item.type]
  let suffix = ''

  if (item.type === FranchiseeTransactionType.REPLENISHMENT) {
    suffix = REPLENISHMENT_TYPES[item.replenishmentType!]
  }

  return (
    <div>
      <Typography>
        {value}
        {suffix}
      </Typography>
      {item.executedBy && (
        <Typography variant='caption' color='textSecondary'>
          Выполнил: {item.executedBy.fullName}
        </Typography>
      )}
      {!!item.psPaymentId && (
        <Typography component='p' variant='caption' color='textSecondary'>
          ID платежа: {item.psPaymentId}
        </Typography>
      )}
    </div>
  )
}
