import React, { useState, useCallback } from 'react'
import { Dialog, DialogContent, DialogActions, Button, DialogProps, ButtonProps, Box } from '@material-ui/core'

export type ConfirmContextProps = (confirmProps: IConfirmProps) => Promise<any>

export const ConfirmContext = React.createContext<ConfirmContextProps | null>(null)

export interface IConfirmProps {
  message?: string
  labelOk?: string
  labelCancel?: string
  dialogProps?: DialogProps
  confirmButtonProps?: ButtonProps
  cancelButtonProps?: ButtonProps
}

interface IResolveReject {
  resolve?: ((value?: unknown) => void) | undefined
  reject?: ((reason?: unknown) => void) | undefined
}

const ConfirmProvider: React.FC<IConfirmProps> = ({
  children,
  message = '',
  labelOk = 'Да',
  labelCancel = 'Нет',
  dialogProps,
  confirmButtonProps = {},
  cancelButtonProps = {},
}) => {
  const defaultOptions: IConfirmProps = {
    message,
    labelOk,
    labelCancel,
    dialogProps,
    confirmButtonProps,
    cancelButtonProps,
  }
  const [options, setOptions] = useState({
    message,
    labelOk,
    labelCancel,
    dialogProps,
    confirmButtonProps,
    cancelButtonProps,
  })
  const [resolveReject, setResolveReject] = useState<IResolveReject>({})
  const { resolve, reject } = resolveReject
  const confirm = useCallback(
    (confirmOptions = {}) => {
      return new Promise((resolve, reject) => {
        setOptions({ ...defaultOptions, ...confirmOptions })
        setResolveReject({ resolve, reject })
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  const handleClose = useCallback(() => {
    setResolveReject({})
  }, [])

  const handleCancel = useCallback(() => {
    reject && reject()
    handleClose()
  }, [reject, handleClose])

  const handleConfirm = useCallback(() => {
    resolve && resolve()
    handleClose()
  }, [resolve, handleClose])
  const isOpen = !!resolveReject.reject && !!resolveReject.resolve
  return (
    <>
      <ConfirmContext.Provider value={confirm}>{children}</ConfirmContext.Provider>
      <Dialog open={isOpen} maxWidth='sm' {...options.dialogProps}>
        <DialogContent>
          <Box minWidth={200} mb={2}>{options.message}</Box>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCancel}
            color='default'
            size='small'
            variant={'contained'}
            {...options.cancelButtonProps}
          >
            {options.labelCancel}
          </Button>
          <Button
            onClick={handleConfirm}
            color='primary'
            size='small'
            variant={'contained'}
            {...options.confirmButtonProps}
          >
            {options.labelOk}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default ConfirmProvider
