import React from 'react'
import { Box, Dialog, DialogTitle, DialogContent } from '@material-ui/core'
import { Alert } from '@material-ui/lab'

import {
  ISalesManager,
  ISalesManagerInput,
  IUpdateSalesManagerData,
  IUpdateSalesManagerVariables,
} from '../../../../graphql/types/user'
import { ManagerForm, IManagerFormValues } from './ManagerForm'
import { useMutation } from '@apollo/react-hooks'
import {
  SALES_MANAGERS_QUERY,
  SALES_MANAGER_BY_ID_QUERY,
  UPDATE_SALES_MANAGER_MUTATION,
} from '../../../../graphql/queries/users'
import { useSnackbar } from 'notistack'

interface IProps {
  open: boolean
  manager: ISalesManager
  onCancel: () => void
}

export const EditManagerModal: React.FC<IProps> = ({ open, manager, onCancel }) => {
  const [error, setError] = React.useState<string | undefined>(undefined)
  const { enqueueSnackbar } = useSnackbar()
  const [updateSalesManager, { loading }] = useMutation<IUpdateSalesManagerData, IUpdateSalesManagerVariables>(
    UPDATE_SALES_MANAGER_MUTATION,
    {
      refetchQueries: [
        { query: SALES_MANAGERS_QUERY },
        { query: SALES_MANAGER_BY_ID_QUERY, variables: { id: manager.id } },
      ],
    }
  )

  const handleSubmit = async (values: IManagerFormValues) => {
    if (!values.promocodes || values.promocodes.length === 0) {
      return
    }

    const input: ISalesManagerInput = {
      firstName: values.firstName,
      phone: values.phone,
      email: values.email,
      promocodeIds: values.promocodes?.map(promocode => promocode.id),
    }

    try {
      await updateSalesManager({ variables: { id: manager.id, input } })
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' })
    }

    if (error) {
      setError(error)
    } else {
      onCancel()
    }
  }

  return (
    <Dialog open={open} maxWidth='sm' fullWidth onClose={onCancel}>
      <DialogTitle id='form-dialog-title'>Изменить менеджера</DialogTitle>
      <DialogContent>
        {!!error && (
          <Box mb={3}>
            <Alert severity='error'>{error}</Alert>
          </Box>
        )}
        <Box>
          <ManagerForm loading={loading} initialData={{
            email: manager.user.email,
            firstName: manager.user.fullName,
            phone: manager.user.phone,
            promocodes: manager.promocodes
          }} onSubmit={handleSubmit} onCancel={onCancel} />
        </Box>
      </DialogContent>
    </Dialog>
  )
}
