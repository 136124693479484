import React from 'react'
import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { green, red, grey } from '@material-ui/core/colors'
import cn from 'classnames'

import { IBusinessTransaction } from '../../../graphql/types/businesses'
import {
  TransactionDirection,
  TransactionFeeType,
  BusinessTransactionType,
  BusinessTransactionStatus,
} from '../../../core/interfaces'

interface IProps {
  item: IBusinessTransaction
  currency: string
}

export const AmountColumn: React.FC<IProps> = ({ item, currency }) => {
  const classes = useStyles()
  const isInactive = [
    BusinessTransactionStatus.PENDING,
    BusinessTransactionStatus.FAILURE,
    BusinessTransactionStatus.INCOMPLETE,
  ].includes(item.status)

  if (item.direction === TransactionDirection.IN) {
    return (
      <Typography className={cn(classes.amount, isInactive ? classes.inactive : classes.in)}>{`${
        item.amount === 0 ? '' : '+'
      }${item.amount} ${currency}`}</Typography>
    )
  } else if (item.direction === TransactionDirection.OUT) {
    return (
      <Typography>
        <Typography component='span' className={cn(classes.amount, classes.out)}>
          {`-${item.amount} ${currency}`}
        </Typography>
        {item.transactionFeeType === TransactionFeeType.PERCENT &&
          item.type === BusinessTransactionType.TRANSACTION_FEE && (
            <Typography component='span' color='textSecondary'>{` (${item.transactionFee}%)`}</Typography>
          )}
      </Typography>
    )
  } else {
    return <Typography>{`${item.amount} ${currency}`}</Typography>
  }
}

const useStyles = makeStyles(theme => ({
  amount: {
    fontWeight: theme.typography.fontWeightMedium,
  },
  in: {
    color: green[500],
  },
  out: {
    color: red[500],
  },
  inactive: {
    color: grey[400],
  },
}))
