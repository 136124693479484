import React from 'react'
import { Paper, Avatar, Typography, Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { LockOutlined as LockIcon } from '@material-ui/icons'
import { Alert } from '@material-ui/lab'
import { useMutation } from '@apollo/react-hooks'
import { inject } from 'mobx-react'
import { RouteComponentProps, Redirect } from 'react-router-dom'

import { logo } from '../../core/images'
import { LOGIN_MUTATION } from '../../graphql/queries/auth'
import { ILoginData, ILoginVariables } from '../../graphql/types/auth'
import { AuthStore } from '../../stores/AuthStore'
import { LoginForm, ILoginFormValues } from './components/LoginForm'

interface IProps {
  authStore: AuthStore
}

const Login: React.FC<IProps & RouteComponentProps> = ({ authStore, history }) => {
  const classes = useStyles()
  const [loading, setLoading] = React.useState<boolean>(false)
  const [error, setError] = React.useState<string>()
  const [login] = useMutation<ILoginData, ILoginVariables>(LOGIN_MUTATION)

  const handleSubmit = async (values: ILoginFormValues) => {
    try {
      setLoading(true)

      const { data } = await login({
        variables: {
          phone: values.phone,
          password: values.password,
        },
      })

      const result = data?.loginFranchiseeByPassword

      if (result) {
        authStore.login(result.user, result.token, result.refreshToken)
        history.push('/')
      }
    } catch (error) {
      setError(error.message)
      setLoading(false)
    }
  }

  if (authStore.loggedIn) {
    return <Redirect to='/' />
  }

  return (
    <main className={classes.main}>
      <div className={classes.container}>
        <Box textAlign='center' color='#fff' mt={4}>
          <img src={logo} className={classes.logo} alt='' />
          <Typography component='h1' variant='h6' color='inherit' noWrap>
            PAYDA FRANCHISEE
          </Typography>
        </Box>
        <Paper className={classes.paper} elevation={0}>
          <Avatar className={classes.avatar}>
            <LockIcon />
          </Avatar>
          <Typography component='h1' variant='h5'>
            Войти
          </Typography>
          {!!error && (
            <Box my={2} width='100%'>
              <Alert severity='error'>{error}</Alert>
            </Box>
          )}
          <LoginForm loading={loading} onSubmit={handleSubmit} />
        </Paper>
        <Box mt={4} color='rgba(255, 255, 255, 0.5)'>
          <Typography color='inherit' variant='body2'>
            &copy; PAYDA 2021. Все права защищены.
          </Typography>
        </Box>
      </div>
    </main>
  )
}

const useStyles = makeStyles((theme) => ({
  main: {
    width: '100%',
    height: '100vh',
    display: 'block', // Fix IE 11 issue.
    backgroundColor: theme.palette.primary.dark,
  },
  container: {
    width: 'auto',
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    paddingTop: theme.spacing(5),
    textAlign: 'center',
    [theme.breakpoints.up(400 + theme.spacing(3 * 2))]: {
      width: 400,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`,
  },
  logo: {
    width: 60,
    marginBottom: theme.spacing(1),
  },
  avatar: {
    color: '#fff',
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
}))

export default inject('authStore')(Login)
