import { ComponentType } from 'react'
import { SvgIconComponent } from '@material-ui/icons'

export interface IRouteItem {
  path: string
  name: string
  component: ComponentType<any>
}

export interface IDrawerRouteItem {
  name: string
  title: string
  path: string
  icon: SvgIconComponent
  disabled?: boolean
  allowedRoles?: UserRole[]
  children?: IDrawerRouteItem[]
}

export enum FranchiseeType {
  PERSON = 0,
  COMPANY = 1,
}

export enum FranchiseeCommissionType {
  FIX = 0,
  PERCENT = 1,
}

export enum FranchiseeTransactionType {
  REPLENISHMENT = 1,
  COMMISSION = 2,
}

export enum FranchiseeReplenishmentType {
  CASH = 1,
  CASHLESS = 2,
  ONLINE = 3,
}

export enum FranchiseeTransactionStatus {
  PENDING = 'pending',
  SUCCESS = 'success',
  FAILURE = 'failure',
  INCOMPLETE = 'incomplete',
}

export enum TransactionDirection {
  IN = 'in',
  OUT = 'out',
}

export enum BusinessBalanceType {
  DEFAULT = 'default',
  BONUS = 'bonus',
}

export enum PaymentType {
  CASH = 1,
  CASHLESS = 2,
}

export enum BusinessTransactionType {
  REPLENISHMENT = 1,
  TRANSACTION_FEE = 2,
  WELCOME_BONUS = 3,
  REGISTRATION_FEE = 4,
  WITHDRAW_EXPIRED_BALANCE = 5,
  OTHER = 6,
}

export enum BusinessTransactionStatus {
  PENDING = 'pending',
  SUCCESS = 'success',
  FAILURE = 'failure',
  INCOMPLETE = 'incomplete',
}

export enum TransactionFeeType {
  PERCENT = 'percent',
  FIX = 'fix',
}

export interface ISelectItem<ValueT> {
  value: ValueT
  label: string
}

export enum UserRole {
  FRANCHISEE = 'franchisee',
  SALES_SUPERVISOR = 'franchisee:sales:supervisor',
  SALES_MANAGER = 'franchisee:sales:manager',
}

export enum PromocodeDiscountType {
  PERCENT = 'percent',
  FIX = 'fix',
}

export enum ApplicationType {
  BUSINESS = 'business',
  CLIENT = 'client',
}

export interface IPagination {
  offset: number
  limit: number
  page: number
}
