import React from 'react'
import { Formik, FormikProps } from 'formik'
import { useSnackbar } from 'notistack'
import { observer } from 'mobx-react'
import { TextField, Box, Container, Typography } from '@material-ui/core'
import { useMutation } from '@apollo/react-hooks'

import { maskPhone } from '../../utils/helpers'
import { PageHeader, Content } from '../../components/Layout'
import useStores from '../../stores/hooks/useStores'
import { IUser, IUpdateUserProfileData, IUpdateUserProfileVariables } from '../../graphql/types/user'
import { UPDATE_USER_PROFILE_MUTATION } from '../../graphql/queries/user'
import { LoadableButton } from '../../components/Form'
import ChangePasswordForm from './ChangePasswordForm'
import { updateProfileSchema } from './validation'

type UpdateProfileFormProps = Pick<IUser, 'firstName' | 'email'>

export default observer(function Profile() {
  const [updateUserProfile, { loading }] = useMutation<IUpdateUserProfileData, IUpdateUserProfileVariables>(
    UPDATE_USER_PROFILE_MUTATION
  )

  const { authStore } = useStores()
  const { enqueueSnackbar } = useSnackbar()

  const handleSubmit = async (values: UpdateProfileFormProps) => {
    try {
      await updateUserProfile({ variables: { input: values } }).then(({ data }) => {
        if (data?.updateUserProfile) {
          authStore.setUser(data?.updateUserProfile)
        }
      })
      enqueueSnackbar('Профиль успешно обновлен!', { variant: 'success' })
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' })
    }
  }

  const initialValues: UpdateProfileFormProps = {
    firstName: authStore.user?.firstName || '',
    email: authStore.user?.email || '',
  }

  return (
    <div>
      <PageHeader title='Профиль' />
      <Container maxWidth='sm'>
        <Content>
          <Box mb={2}>
            <Typography variant='caption' color='textSecondary'>
              Телефон (Логин)
            </Typography>
            {authStore.user && <Typography variant='subtitle2'>{maskPhone(authStore.user.phone)}</Typography>}
          </Box>
          <Formik initialValues={initialValues} validationSchema={updateProfileSchema} onSubmit={handleSubmit}>
            {({ values, errors, handleChange, handleSubmit }: FormikProps<UpdateProfileFormProps>) => (
              <form onSubmit={handleSubmit}>
                <Box>
                  <TextField
                    label='Имя'
                    error={!!errors.firstName}
                    name='firstName'
                    helperText={errors.firstName}
                    value={values.firstName || ''}
                    onChange={handleChange}
                    fullWidth
                    margin='normal'
                  />
                </Box>
                <Box mb={3}>
                  <TextField
                    label='Email'
                    error={!!errors.email}
                    helperText={errors.email}
                    name='email'
                    value={values.email || ''}
                    onChange={handleChange}
                    fullWidth
                    margin='normal'
                  />
                </Box>
                <Box textAlign='right'>
                  <LoadableButton loading={loading} type='submit' variant='contained' color='primary'>
                    Сохранить
                  </LoadableButton>
                </Box>
              </form>
            )}
          </Formik>
        </Content>
        <Box mt={2}>
          <ChangePasswordForm />
        </Box>
      </Container>
    </div>
  )
})
