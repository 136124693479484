import React from 'react'
import { Box, Typography } from '@material-ui/core'

import { IBusinessTransaction } from '../../../graphql/types/businesses'
import { BusinessTransactionStatus } from '../../../core/interfaces'
import { STATUS_OPTIONS } from '../../../core/constants'

interface IProps {
  item: IBusinessTransaction
}

export const BalanceTransactionStatus: React.FC<IProps> = ({ item }) => {
  const status = STATUS_OPTIONS[item.status]

  return (
    <Box color={status.color}>
      <Typography color='inherit'>{status.label}</Typography>
      {!!item.error && (
        <Typography
          color={item.status === BusinessTransactionStatus.INCOMPLETE ? 'inherit' : 'error'}
          variant='caption'
        >
          {!!item.errorCode && `[${item.errorCode}] `} {item.error}
        </Typography>
      )}
    </Box>
  )
}
