import React, { ChangeEvent } from 'react'
import { Box, Typography, TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'

import { useQuery } from '@apollo/react-hooks'
import { SALES_MANAGERS_QUERY } from '../../../graphql/queries/users'
import { ISalesManager, ISalesManagersData, ISalesManagersVariables } from '../../../graphql/types/user'

interface IProps {
  managerId?: number | null
  value: ISalesManager | null
  onSelect: (option: ISalesManager | null) => void
  error?: boolean
}

export const SalesManagerAutocomplete: React.FC<IProps> = ({ value, onSelect, error }) => {
  const [open, setOpen] = React.useState<boolean>(false)

  const { data: { salesManagersForFranchisee = [] } = {}, loading } = useQuery<
    ISalesManagersData,
    ISalesManagersVariables
  >(SALES_MANAGERS_QUERY, { fetchPolicy: 'cache-and-network' })

  const renderOption = (option: ISalesManager) => (
    <Box width='100%' display='flex' flexDirection='row' justifyContent='space-between'>
      <Typography>
        <Typography display='inline' component='span'>
          {option.user.fullName}
        </Typography>
        <Typography display='inline' component='span' color='textSecondary'>
          {' '}
          - {option.id}
        </Typography>
      </Typography>
    </Box>
  )

  const handleChange = (event: ChangeEvent<{}>, option: ISalesManager | null) => {
    onSelect(option)
  }

  return (
    <Autocomplete
      open={open}
      onOpen={() => {
        setOpen(true)
      }}
      onClose={() => {
        setOpen(false)
      }}
      value={value}
      getOptionSelected={(option, value) => option.id === value.id}
      getOptionLabel={(option) => `${option.user.fullName}`}
      renderOption={renderOption}
      options={salesManagersForFranchisee}
      loading={loading}
      noOptionsText={'Не найдено'}
      onChange={handleChange}
      fullWidth
      renderInput={(params) => (
        <TextField
          {...params}
          label='Менеджер'
          error={error}
          margin='normal'
          fullWidth
          InputProps={{
            ...params.InputProps,
          }}
        />
      )}
    />
  )
}
