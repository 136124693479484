import React from 'react'
import { Typography } from '@material-ui/core'

import { IBusinessTransaction } from '../../../graphql/types/businesses'
import { BUSINESS_TRANSACTION_TYPE_LABELS } from '../../../core/constants'
import { BusinessTransactionType, PaymentType } from '../../../core/interfaces'

interface IProps {
  item: IBusinessTransaction
}

export const TypeColumn: React.FC<IProps> = ({ item }) => {
  const value = BUSINESS_TRANSACTION_TYPE_LABELS[item.type]
  let suffix = ''
  if (item.type === BusinessTransactionType.REPLENISHMENT && item.paymentType === PaymentType.CASH) {
    suffix = ' (наличный)'
  }
  if (item.type === BusinessTransactionType.REPLENISHMENT && item.paymentType === PaymentType.CASHLESS) {
    suffix = ' (безналичный)'
  }

  return (
    <div>
      <Typography>{value}{suffix}</Typography>
      {item.type === BusinessTransactionType.OTHER && !!item.typeComment && (
        <Typography component='p' variant='caption' color='textSecondary'>
          {item.typeComment}
        </Typography>
      )}
      {item.executedBy && (
        <Typography variant='caption' color='textSecondary'>
          Выполнил: {item.executedBy.fullName}
        </Typography>
      )}
      {!!item.psPaymentId && (
        <Typography component='p' variant='caption' color='textSecondary'>
          ID платежа: {item.psPaymentId}
        </Typography>
      )}
    </div>
  )
}
