import React, { Fragment, useState } from 'react'
import { useQuery } from '@apollo/react-hooks'
import { RouteComponentProps } from 'react-router-dom'
import { Box, Divider, IconButton, Typography } from '@material-ui/core'
import { FilterList as FilterIcon, Close as CloseIcon } from '@material-ui/icons'

import { ROWS_PER_PAGE } from '../../core/constants'
import { ALL_BUSINESSES_QUERY } from '../../graphql/queries/businesses'
import { IAllBusinessesData, IAllBusinessesVariables, IBusiness } from '../../graphql/types/businesses'
import { IPaginationInput } from '../../graphql/types/common'
import { PageHeader, Content } from '../../components/Layout'
import { Loader } from '../../components/UI'
import { DataTable, EmptyDataMessage } from '../../components/Data'
import Pagination from '../../components/Data/pagination/Pagination'
import { columns } from './businessesColumns'
import { useBusinessesFilter } from './components'

interface IProps extends RouteComponentProps {}

const Businesses: React.FC<IProps> = ({ history }) => {
  const { filter, filterVisible, toggleFilter, renderFilter } = useBusinessesFilter()
  const [currentPage, setCurrentPage] = useState<number>(0)
  const [pagination, setPagination] = useState<IPaginationInput>({ offset: 0, limit: ROWS_PER_PAGE[0] })

  const { data, loading } = useQuery<IAllBusinessesData, IAllBusinessesVariables>(ALL_BUSINESSES_QUERY, {
    variables: {
      pagination,
      filter,
    },
  })
  const businesses = data?.businessesForFranchisee?.businesses || []
  const total = data?.businessesForFranchisee?.total || 0

  const handleClickItem = (item: IBusiness) => {
    history.push(`/businesses/${item.id}`)
  }

  const handleChangePerPage = (limit: number) => {
    setPagination({ offset: currentPage * limit, limit })
  }

  const handleChangePage = (page: number) => {
    setCurrentPage(page)
    setPagination({ ...pagination, offset: page * pagination.limit })
  }

  return (
    <div>
      <PageHeader title='Партнеры' />
      <Content noGutter>
        <Box p={2} display='flex' alignItems='center' justifyContent='space-between'>
          <Typography>Всего: {total}</Typography>
          <IconButton onClick={toggleFilter}>{filterVisible ? <CloseIcon /> : <FilterIcon />}</IconButton>
        </Box>
        {renderFilter()}
        <Divider />
        {loading ? (
          <Loader />
        ) : (
          <Fragment>
            {businesses.length > 0 ? (
              <Fragment>
                <DataTable data={businesses} columns={columns} onClickItem={handleClickItem} />
                <Box mt={3}>
                  <Pagination
                    rowsPerPage={pagination.limit}
                    rowsPerPageOptions={ROWS_PER_PAGE}
                    onChangeRowsPerPage={handleChangePerPage}
                    onChangePage={handleChangePage}
                    page={currentPage}
                    count={total}
                  />
                </Box>
              </Fragment>
            ) : (
              <EmptyDataMessage />
            )}
          </Fragment>
        )}
      </Content>
    </div>
  )
}

export default Businesses
