import React from 'react'

import { Box, makeStyles, Typography } from '@material-ui/core'
import { green, grey, red } from '@material-ui/core/colors'
import { ArrowRightAlt } from '@material-ui/icons'

interface Props {
  current: number
  prev: number
  isFloat?: boolean
}

const useStyles = makeStyles({
  arrowUp: {
    transform: 'rotate(-0.25turn)',
    color: green[500],
    fontSize: 16,
  },
  arrowDown: {
    transform: 'rotate(0.25turn)',
    color: red[500],
    fontSize: 16,
  },
  value: {
    fontSize: 12,
  },
})

export const DiffValue: React.FC<Props> = ({ current, prev, isFloat = false }) => {
  const classes = useStyles()
  const diffValue = current - prev
  if (current > prev) {
    return (
      <Box ml={1} display='inline-flex' alignItems='center'>
        <ArrowRightAlt fontSize='small' className={classes.arrowUp} />
        <Box color={green[800]}>
          <Typography className={classes.value}>{isFloat ? diffValue.toFixed(1) : diffValue}</Typography>
        </Box>
      </Box>
    )
  }
  if (current < prev) {
    return (
      <Box ml={1} display='inline-flex' alignItems='center'>
        <ArrowRightAlt fontSize='small' className={classes.arrowDown} />
        <Box color={red[800]}>
          <Typography className={classes.value}>{isFloat ? diffValue.toFixed(1) : diffValue}</Typography>
        </Box>
      </Box>
    )
  }
  return (
    <Box ml={1} color={grey[300]} component='span'>
      =
    </Box>
  )
}
