import React, { Fragment } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Typography, Grid, IconButton } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { KeyboardArrowLeft } from '@material-ui/icons'

interface IProps {
  title: string
  right?: React.ReactNode
  parentPath?: string
  back?: boolean
}

export const PageHeader: React.FC<IProps> = ({ title, right, parentPath, back }) => {
  const classes = useStyles()
  const history = useHistory()

  return (
    <div className={classes.root}>
      <Grid container alignItems='center' spacing={1}>
        <Grid item xs className={classes.section1}>
          {(!!parentPath || back) && (
            <Fragment>
              {!!parentPath && (
                <IconButton component={Link} to={parentPath}>
                  <KeyboardArrowLeft />
                </IconButton>
              )}
              {back && (
                <IconButton onClick={history.goBack}>
                  <KeyboardArrowLeft />
                </IconButton>
              )}
            </Fragment>
          )}
          <Typography variant='h4' component='h2' className={classes.title}>
            {title}
          </Typography>
        </Grid>
        {!!right && <Grid item>{right}</Grid>}
      </Grid>
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: theme.spacing(2),
  },
  section1: {
    display: 'flex',
    alignItems: 'center',
  },
  title: {
    ...theme.typography.h5,
    fontWeight: theme.typography.fontWeightLight,
    padding: `${theme.spacing(1)}px 0`,
  },
}))
