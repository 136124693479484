import gql from 'graphql-tag'

export const FILTERED_BUSINESS_TRANSACTIONS_QUERY = gql`
  query FilteredBusinessTransactions(
    $filter: BusinessTransactionFilterInput
    $pagination: PaginationInput
    $sortBy: BusinessTransactionSortInput
  ) {
    filteredBusinessTransactionsForFranchisee(filter: $filter, pagination: $pagination, sortBy: $sortBy) {
      transactions {
        id
        amount
        direction
        type
        typeComment
        paymentType
        status
        psPaymentId
        paymentDate
        description
        error
        errorCode
        balanceType
        business {
          id
          companyName
          country {
            id
            name
            numberCode
            code
            currencyCode
            currencySymbol
            minTransactionFee
            registrationPrice
          }
          promocodes {
            id
            promocode {
              title
              discount
              discountType
              discountValue
              salesManager {
                id
                user {
                  phone
                  email
                  id
                  fullName
                }
              }
            }
          }
        }
        transactionFee
        transactionFeeType
        transaction {
          id
          cashbackPercent
          amount
        }
        executedBy {
          id
          phone
          fullName
        }
        createdAt
        updatedAt
      }
      amount
      total
    }
  }
`

export const FILTERED_BUSINESS_TRANSACTIONS_XLSX_MUTATION = gql`
  mutation FilteredBusinessTransactionsXLSX($filter: BusinessTransactionFilterInput!, $timeZoneOffset: Int) {
    filteredBusinessTransactionsXLSXByFranchisee(responseType: url, filter: $filter, timeZoneOffset: $timeZoneOffset)
  }
`

export const TRANSACTION_REPORT_BY_BUSINESS_XLSX_MUTATION = gql`
  mutation TransactionReportByBusinessXLSX($businessId: Int, $filter: TransactionsFilterInput, $timeZoneOffset: Int) {
    transactionReportByBusinessXLSXByFranchisee(
      businessId: $businessId
      responseType: url
      filter: $filter
      timeZoneOffset: $timeZoneOffset
    )
  }
`
