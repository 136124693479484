import React from 'react'
import { FormControlLabel, Switch } from '@material-ui/core'

import { IBusiness } from '../../../graphql/types/businesses'
import { useSwitchBusinessVisibility } from '../../../graphql/hooks/businesses'

interface IProps {
  business: IBusiness
}

export const BusinessVisibility: React.FC<IProps> = ({ business }) => {
  const switchBusinessVisiblity = useSwitchBusinessVisibility()
  const [isVisible, setIsVisible] = React.useState<boolean>(business.isVisible)

  const handleSwitchVisibility = async () => {
    await switchBusinessVisiblity(business.id)

    setIsVisible(!isVisible)
  }

  return <FormControlLabel control={<Switch checked={isVisible} />} label='видимый' onChange={handleSwitchVisibility} />
}
