import React from 'react'
import moment from 'moment'

import { DATE_TIME_FORMAT } from '../../../core/constants'
import { IDataTableColumn } from '../../../components/Data'
import { IBusiness } from '../../../graphql/types/businesses'
import { ActivationColumn } from './ActivationColumn'
import { AvatarColumn } from './AvatarColumn'
import { BalanceColumn } from './BalanceColumn'
import { VisibilityColumn } from './VisibilityColumn'
import { TransactionFeeColumn } from './TransactionFeeColumn'

export const columns: IDataTableColumn<IBusiness>[] = [
  { label: 'ID', accessor: 'id', style: { width: 70 } },
  { label: '', accessor: (item) => <AvatarColumn business={item} />, style: { width: 75 } },
  { label: 'Название', accessor: 'companyName' },
  { label: 'Активация', accessor: (item) => <ActivationColumn business={item} />, align: 'center' },
  { label: 'Видимость', accessor: (item) => <VisibilityColumn business={item} />, align: 'center' },
  { label: 'Баланс', accessor: (item) => <BalanceColumn business={item} /> },
  { label: 'Комиссия', accessor: (item) => <TransactionFeeColumn business={item} /> },
  { label: 'Дата регистрации', accessor: (item) => moment(item.createdAt).format(DATE_TIME_FORMAT) },
]
