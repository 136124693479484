import gql from 'graphql-tag'

import { USER_FRAGMENT } from './user'

export const FRANCHISE_FRAGMENT = gql`
  fragment FranchiseeFragment on Franchisee {
    id
    name
    email
    type
    balance
    createdAt
    updatedAt
    settings {
      id
      commission
      commissionType
      commissionMin
      processing
      businessCommission {
        percent {
          min
          max
        }
        fixed {
          min
          max
        }
      }
    }
    city {
      id
      name
      country {
        id
        name
        numberCode
        code
        currencyCode
        currencySymbol
      }
    }
  }
`

export const FRANCHISEE_PROFILE = gql`
  query FranchiseeProfileQuery {
    franchiseeProfile {
      user {
        ...UserFragment
      }
      franchisee {
        ...FranchiseeFragment
      }
    }
  }
  ${USER_FRAGMENT}
  ${FRANCHISE_FRAGMENT}
`

export const FRANCHISEE_BALANCE_TRANSACTIONS_QUERY = gql`
  query FranchiseeBalanceTransactionsQuery($pagination: PaginationInput) {
    franchiseeBalanceTransactions(pagination: $pagination) {
      transactions {
        id
        amount
        direction
        type
        replenishmentType
        comment
        status
        businessTransaction {
          id
          amount
          business {
            id
            companyName
          }
        }
        executedBy {
          id
          fullName
        }
        createdAt
        updatedAt
        commission
        commissionType
        psPaymentId
        paymentDate
        description
        error
        errorCode
      }
      total
    }
  }
`
