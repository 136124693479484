import { IRouteItem } from '../core/interfaces'

import Home from '../views/Home/Home'
import Logout from '../views/Auth/Logout'
import Businesses from '../views/Businesses/Businesses'
import BusinessDetail from '../views/Businesses/BusinessDetail'
import BusinessEdit from '../views/Businesses/BusinessEdit'
import BusinessBalanceTransactions from '../views/Businesses/BusinessBalanceTransactions'
import SalesSupervisors from '../views/Sales/Supervisors/Supervisors'
import SalesManagers from '../views/Sales/Managers/Managers'
import Promocodes from '../views/Promocodes/Promocodes'
import Profile from '../views/Profile/Profile'
import SalesManager from '../views/Sales/Managers/Manager'
import Transactions from '../views/Statistics/Transactions/Transactions'
import BusinessSummaryReport from '../views/Statistics/BusinessSummary/BusinessSummaryReport'
import BusinessTransactionFeesByDaysReport from '../views/Statistics/BusinessTransactionFeesByDay/BusinessTransactionFeesByDay'
import Balance from '../views/Balance/Balance'

const items: IRouteItem[] = [
  {
    name: 'home',
    path: '/',
    component: Home,
  },
  {
    name: 'logout',
    path: '/logout',
    component: Logout,
  },
  {
    name: 'businesses',
    path: '/businesses',
    component: Businesses,
  },
  {
    name: 'business',
    path: '/businesses/:id',
    component: BusinessDetail,
  },
  {
    name: 'business-edit',
    path: '/businesses/:id/edit',
    component: BusinessEdit,
  },
  {
    name: 'business-balance-transactions',
    path: '/businesses/:id/balance',
    component: BusinessBalanceTransactions,
  },
  {
    name: 'sales.supervisors',
    path: '/sales/supervisors',
    component: SalesSupervisors,
  },
  {
    name: 'sales.managers',
    path: '/sales/managers',
    component: SalesManagers,
  },
  {
    name: 'sales.managers',
    path: '/sales/managers/:id',
    component: SalesManager,
  },
  {
    name: 'promocodes',
    path: '/promocodes',
    component: Promocodes,
  },
  {
    name: 'profile',
    path: '/profile',
    component: Profile,
  },
  {
    name: 'statistics.transactions',
    path: '/statistics/transactions',
    component: Transactions,
  },
  {
    name: 'statistics.summary',
    path: '/statistics/summary',
    component: BusinessSummaryReport,
  },
  {
    name: 'statistics.transaction-fee',
    path: '/statistics/transaction-fee',
    component: BusinessTransactionFeesByDaysReport,
  },
  {
    name: 'balance',
    path: '/balance',
    component: Balance,
  },
]

export default items
