import * as yup from 'yup'

export const changePasswordSchema = yup.object().shape({
  oldPassword: yup.string().required('Необходимо ввести старый пароль'),
  newPassword: yup
    .string()
    .min(6, 'Пароль должен быть не меньше 6 символов')
    .required('Необходимо ввести новый пароль'),
  confirmPassword: yup.string().oneOf([yup.ref('newPassword'), null], 'Passwords must match'),
})

export const updateProfileSchema = yup.object().shape({
  firstName: yup.string().required('Необходимо ввести ваше имя'),
  email: yup
    .string()
    .email('Email должен быть валидный')
    .required(),
})
