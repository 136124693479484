import React from 'react'
import { useMutation } from '@apollo/react-hooks'

import { CREATE_SALES_SUPERVISOR_MUTATION, SALES_SUPERVISORS_QUERY } from '../../queries/users'
import { ISalesSupervisorInput, ICreateSalesSupervisorData, ICreateSalesSupervisorVariables } from '../../types/user'

export const useCreateSalesSupervisor = () => {
  const [loading, setLoading] = React.useState<boolean>(false)
  const [error, setError] = React.useState<string | null>(null)
  const [createSalesSupervisorMutation] = useMutation<ICreateSalesSupervisorData, ICreateSalesSupervisorVariables>(
    CREATE_SALES_SUPERVISOR_MUTATION
  )

  const createSalesSupervisor = async (input: ISalesSupervisorInput) => {
    try {
      setLoading(true)

      await createSalesSupervisorMutation({
        variables: { input },
        refetchQueries: [
          {
            query: SALES_SUPERVISORS_QUERY,
          },
        ],
      })
    } catch (error) {
      setError(error.message)
    } finally {
      setLoading(false)
    }
  }

  return {
    createSalesSupervisor,
    loading,
    error,
  }
}
