import React from 'react'
import { Fab, makeStyles } from '@material-ui/core'
import { Add as AddIcon } from '@material-ui/icons'

interface IProps {
  onClick: () => void
}

export const CreateFab: React.FC<IProps> = ({ onClick }) => {
  const classes = useStyles()

  return (
    <Fab color='primary' className={classes.fab} onClick={onClick}>
      <AddIcon />
    </Fab>
  )
}

const useStyles = makeStyles(theme => ({
  fab: {
    position: 'absolute',
    bottom: theme.spacing(3),
    right: theme.spacing(3),
  },
}))
