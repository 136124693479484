import React from 'react'
import { Button, TextField, Box, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio } from '@material-ui/core'
import { Formik, FormikProps } from 'formik'
import * as yup from 'yup'
import { useMutation } from '@apollo/react-hooks'

import {
  ADD_BALANCE_TO_BUSINESS_MUTATION,
  BUSINESS_BALANCE_TRANSACTIONS_QUERY,
  BUSINESS_DETAIL_QUERY,
} from '../../../../graphql/queries/businesses'
import {
  IBusiness,
  IAddBalanceToBusinessData,
  IAddBalanceToBusinessVariables,
} from '../../../../graphql/types/businesses'
import { LoadableButton } from '../../../../components/Form'
import { LIST_LIMIT } from '../../../../core/constants'
import { BusinessBalanceType, PaymentType } from '../../../../core/interfaces'

interface IProps {
  business: IBusiness
  onClose: () => void
}

interface IAddBalanceFormValues {
  balanceType: BusinessBalanceType
  paymentType: PaymentType
  amount: string
}

const validationSchema = yup.object().shape({
  amount: yup
    .number()
    .moreThan(0)
    .required(),
})

export const AddBalanceForm: React.FC<IProps> = ({ business, onClose }) => {
  const initialValues: IAddBalanceFormValues = {
    amount: '',
    balanceType: BusinessBalanceType.DEFAULT,
    paymentType: PaymentType.CASHLESS,
  }

  const [loading, setLoading] = React.useState<boolean>(false)
  const [addBalanceToBusiness] = useMutation<IAddBalanceToBusinessData, IAddBalanceToBusinessVariables>(
    ADD_BALANCE_TO_BUSINESS_MUTATION
  )

  const handleSubmit = async (values: IAddBalanceFormValues) => {
    try {
      setLoading(true)

      const { data } = await addBalanceToBusiness({
        variables: {
          businessId: business.id,
          amount: parseFloat(values.amount),
          balanceType: values.balanceType,
          paymentType: values.paymentType,
        },
        refetchQueries: [
          {
            query: BUSINESS_BALANCE_TRANSACTIONS_QUERY,
            variables: { businessId: business.id, pagination: { offset: 0, limit: LIST_LIMIT } },
          },
          { query: BUSINESS_DETAIL_QUERY, variables: { id: business.id } },
        ],
      })

      if (data?.addBalanceToBusiness) {
        // TODO: Show success message
        onClose()
      }
    } catch (error) {
      // TODO: Show error message
      console.log(error.message)
      setLoading(false)
    }
  }

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
      {({ values, errors, setFieldValue, handleChange, submitForm }: FormikProps<IAddBalanceFormValues>) => (
        <div>
          <Box py={2}>
            <FormControl component='fieldset'>
              <FormLabel component='legend'>Выберите Баланс</FormLabel>
              <RadioGroup
                aria-label='balance-type'
                name='balanceType'
                value={values.balanceType}
                onChange={handleChange}
              >
                <FormControlLabel value={BusinessBalanceType.DEFAULT} control={<Radio />} label='Основной Баланс' />
                <FormControlLabel value={BusinessBalanceType.BONUS} control={<Radio />} label='Подарочный Баланс' />
              </RadioGroup>
            </FormControl>
          </Box>
          <Box py={2}>
            <FormControl component='fieldset'>
              <FormLabel component='legend'>Выберите Вид Платежа</FormLabel>
              <RadioGroup
                aria-label='payment-type'
                name='paymentType'
                value={values.paymentType}
                onChange={event => setFieldValue('paymentType', parseInt(event.target.value, 10))}
              >
                <FormControlLabel value={PaymentType.CASH} control={<Radio />} label='Наличный платеж' />
                <FormControlLabel value={PaymentType.CASHLESS} control={<Radio />} label='Безналичный платеж' />
              </RadioGroup>
            </FormControl>
          </Box>
          <Box paddingY={2}>
            <TextField
              label='Сумма пополнения'
              name='amount'
              value={values.amount}
              onChange={handleChange}
              required
              fullWidth
              margin='normal'
              error={!!errors.amount}
              autoFocus
              type='number'
            />
          </Box>
          <Box mt={2} display='flex' flexDirection='row' justifyContent='space-between'>
            <Button onClick={onClose} color='default'>
              Закрыть
            </Button>
            <LoadableButton loading={loading} variant='contained' color='primary' onClick={submitForm}>
              Применить
            </LoadableButton>
          </Box>
        </div>
      )}
    </Formik>
  )
}
