import React from 'react'
import { Box, Dialog, DialogTitle, DialogContent } from '@material-ui/core'
import { Alert } from '@material-ui/lab'

import { useCreateSalesManager } from '../../../../graphql/hooks/users'
import { ISalesManagerInput } from '../../../../graphql/types/user'
import { ManagerForm, IManagerFormValues } from './ManagerForm'

interface IProps {
  open: boolean
  onCancel: () => void
}

export const AddManagerModal: React.FC<IProps> = ({ open, onCancel }) => {
  const [error, setError] = React.useState<string | undefined>(undefined)
  const { createSalesManager, loading } = useCreateSalesManager()

  const handleSubmit = async (values: IManagerFormValues) => {
    if (!values.promocodes || values.promocodes.length === 0) {
      return
    }

    const input: ISalesManagerInput = {
      firstName: values.firstName,
      phone: values.phone,
      email: values.email,
      promocodeIds: values.promocodes?.map(promocode => promocode.id),
    }

    const { error } = await createSalesManager(input)

    if (error) {
      setError(error)
    } else {
      onCancel()
    }
  }

  return (
    <Dialog open={open} maxWidth='sm' fullWidth onClose={onCancel}>
      <DialogTitle id='form-dialog-title'>Новый пользователь</DialogTitle>
      <DialogContent>
        {!!error && (
          <Box mb={3}>
            <Alert severity='error'>{error}</Alert>
          </Box>
        )}
        <Box>
          <ManagerForm loading={loading} onSubmit={handleSubmit} onCancel={onCancel} />
        </Box>
      </DialogContent>
    </Dialog>
  )
}
