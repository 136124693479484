import * as yup from 'yup'

import { PHONE_REGEXP } from '../../../core/constants'

export const loginSchema = yup.object().shape({
  phone: yup.string().trim().matches(PHONE_REGEXP, 'Не правильный формат телефона').required(),
  password: yup.string().required(),
})

export const inviteSchema = yup.object().shape({
  password: yup.string().min(8, 'Пароль должен быть больше 8 символов').required('Обязательное поле'),
  confirmPassword: yup.string().oneOf([yup.ref('password'), null], 'Повторный пароль не совпадает'),
})
