import React from 'react'
import { Typography, TypographyProps } from '@material-ui/core'
import moment from 'moment'

import { DATE_TIME_FORMAT } from '../../../core/constants'
import { IBusinessTransaction } from '../../../graphql/types/businesses'

interface IProps {
  item: IBusinessTransaction
}

export const CreatedAtColumn: React.FC<IProps & TypographyProps> = ({ item, ...props }) => {
  return (
    <Typography color='textSecondary' {...props}>
      {moment(item.createdAt).format(DATE_TIME_FORMAT)}
    </Typography>
  )
}
