import React from 'react'
import { Box, CardContent, Card, makeStyles, Typography, Grid, IconButton, ClickAwayListener } from '@material-ui/core'
import { Close as CloseIcon, FilterList as FilterIcon } from '@material-ui/icons'
import { DatePicker } from '@material-ui/pickers'
import moment from 'moment'
import cn from 'classnames'

import { BusinessAutocomplete } from '../components/BusinessAutocomplete'
import { IBusinessShortest } from '../../../graphql/types/businesses'

interface IFilter {
  business: IBusinessShortest | null
  startDate: Date | string
  endDate: Date | string
}

type Value<T, K extends keyof T> = T[K]

export const useBusinessSummaryFilter = () => {
  const classes = useStyles()
  const [filter, setFilter] = React.useState<IFilter>({
    business: null,
    startDate: moment()
      .subtract(2, 'week')
      .startOf('day')
      .toDate(),
    endDate: moment()
      .endOf('day')
      .toDate(),
  })

  const [filterVisible, setFilterVisible] = React.useState<boolean>(false)

  const toggleFilter = () => {
    setFilterVisible(!filterVisible)
  }

  const closeFilter = () => {
    setFilterVisible(false)
  }

  const handleChange = <K extends keyof IFilter>(field: K) => (value: Value<IFilter, K>) => {
    setFilter({ ...filter, [field]: value })
  }

  const renderFilter = () => (
    <ClickAwayListener onClickAway={closeFilter}>
      <Box position='relative'>
        <IconButton onClick={toggleFilter}>
          <FilterIcon />
        </IconButton>
        <Card className={cn(classes.container, { [classes.containerClosed]: !filterVisible })} square>
          <CardContent>
            <div className={classes.appBarSpacer} />
            <Box>
              <Box display='flex' alignItems='center' justifyContent='space-between'>
                <Typography variant='subtitle2'>Фильтр</Typography>
                <IconButton size='small' onClick={toggleFilter}>
                  <CloseIcon />
                </IconButton>
              </Box>
              <Box>
                <BusinessAutocomplete value={filter.business} onSelect={handleChange('business')} />
              </Box>
              <Box>
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <DatePicker
                      value={filter.startDate}
                      format='YYYY-MM-DD'
                      margin='normal'
                      label='От'
                      fullWidth
                      maxDate={filter.endDate}
                      onChange={momentDate => {
                        if (momentDate) {
                          handleChange('startDate')(momentDate.startOf('day').toDate())
                        }
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <DatePicker
                      value={filter.endDate}
                      margin='normal'
                      format='YYYY-MM-DD'
                      fullWidth
                      minDate={filter.startDate}
                      label='До'
                      onChange={momentDate => {
                        if (momentDate) {
                          handleChange('endDate')(momentDate.endOf('day').toDate())
                        }
                      }}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Box>
    </ClickAwayListener>
  )

  return {
    filter,
    renderFilter,
    handleChangeFilter: handleChange,
  }
}

const useStyles = makeStyles(theme => ({
  container: {
    width: 260,
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    overflowY: 'auto',
    overflowX: 'hidden',
    zIndex: theme.zIndex.drawer,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  containerClosed: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: 0,
  },
  appBarSpacer: theme.mixins.toolbar,
}))
