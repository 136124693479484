import gql from 'graphql-tag'

export const All_BUSINESS_SUMMARY_REPORT_QUERY = gql`
  query AllBusinessSummaryReport($input: AllBusinessSummaryReportInput!, $pagination: PaginationInput!) {
    allBusinessSummaryReportForFranchisee(input: $input, pagination: $pagination) {
      reportItems {
        businessId
        companyName
        balance
        lastTransactionAt
        avgAmount
        avgCountPerDay
        avgTransactionFeePerMonth
        planingEndBalanceInDays
      }
      total
    }
  }
`

export const ALL_BUSINESS_TRANSACTION_FEES_BY_DAYS = gql`
  query AllBusinessTransactionFeesByDays(
    $input: AllBusinessTransactionFeesByDaysInput!
    $pagination: PaginationInput!
  ) {
    allBusinessTransactionFeesByDaysForFranchisee(input: $input, pagination: $pagination) {
      data {
        businessId
        companyName
        data {
          rangeName
          amount
          count
          amountPrev
          countPrev
        }
      }
      summary {
        rangeName
        amount
        count
        amountPrev
        countPrev
      }
      total
    }
  }
`
