import React, { Fragment } from 'react'
import { Box, Divider, Grid, MenuItem, TextField } from '@material-ui/core'
import { grey } from '@material-ui/core/colors'
import { debounce } from 'lodash'

import { IBusinessesFilter } from '../../../graphql/types/businesses'

interface IProps {
  filter: IBusinessesFilter
  onChange: (filter: IBusinessesFilter) => void
}

export const BusinessesFilter: React.FC<IProps> = ({ filter, onChange }) => {
  const [query, setQuery] = React.useState<string>(filter.query)
  const [visibility, setVisibility] = React.useState<string>(
    filter.isVisible !== undefined ? (filter.isVisible ? 'true' : 'false') : 'all'
  )
  const [activation, setActivation] = React.useState<string>(
    filter.isActivated !== undefined ? (filter.isActivated ? 'true' : 'false') : 'all'
  )

  const updateFilter = () => {
    let isVisible
    let isActivated

    if (visibility === 'true') {
      isVisible = true
    } else if (visibility === 'false') {
      isVisible = false
    }

    if (activation === 'true') {
      isActivated = true
    } else if (activation === 'false') {
      isActivated = false
    }

    onChange({ query, isVisible, isActivated })
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const delayedUpdateQuery = React.useCallback(debounce(updateFilter, 500), [query, visibility, activation])

  const handleChangeQuery = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(target.value)
  }

  const handleChangeVisibility = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    setVisibility(target.value)
  }

  const handleChangeActivation = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    setActivation(target.value)
  }

  React.useEffect(() => {
    delayedUpdateQuery()

    return delayedUpdateQuery.cancel
  }, [query, visibility, activation, delayedUpdateQuery])

  return (
    <Fragment>
      <Divider />
      <Box paddingX={2} paddingY={1} bgcolor={grey[50]}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={6}>
            <TextField
              label='Название'
              value={query}
              variant='outlined'
              size='small'
              onChange={handleChangeQuery}
              fullWidth
              margin='normal'
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label='Видимость'
              variant='outlined'
              size='small'
              onChange={handleChangeVisibility}
              fullWidth
              margin='normal'
              select
              value={visibility}
            >
              <MenuItem value={'all'}>Все</MenuItem>
              <MenuItem value={'true'}>Видимый</MenuItem>
              <MenuItem value={'false'}>Не видимый</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label='Активация'
              variant='outlined'
              size='small'
              onChange={handleChangeActivation}
              fullWidth
              margin='normal'
              select
              value={activation}
            >
              <MenuItem value={'all'}>Все</MenuItem>
              <MenuItem value={'true'}>Активированные</MenuItem>
              <MenuItem value={'false'}>Не активированные</MenuItem>
            </TextField>
          </Grid>
        </Grid>
      </Box>
    </Fragment>
  )
}
