import React from 'react'
import { Storefront as BusinessIcon, Edit as EditIcon } from '@material-ui/icons'
import { Avatar, Box, CircularProgress, Fab, makeStyles } from '@material-ui/core'
import { observer } from 'mobx-react'
import { useMutation } from '@apollo/react-hooks'
import { IBusiness, IUpdateBusinessAvatarData, IUpdateBusinessAvatarVariables } from '../../../graphql/types/businesses'
import { BUSINESS_DETAIL_QUERY, UPDATE_BUSINESS_AVATAR_MUTATION } from '../../../graphql/queries/businesses'
import { useSnackbar } from 'notistack'

interface IProps {
  business: IBusiness
}

export const EditableBusinessAvatar: React.FC<IProps> = observer(({ business }) => {
  const classes = useStyles()

  const { enqueueSnackbar } = useSnackbar()

  const [updateAvatar, { loading }] = useMutation<IUpdateBusinessAvatarData, IUpdateBusinessAvatarVariables>(
    UPDATE_BUSINESS_AVATAR_MUTATION,
    {
      refetchQueries: [{ query: BUSINESS_DETAIL_QUERY, variables: { id: business.id } }],
    }
  )

  const fileInputRef = React.useRef<HTMLInputElement | null>(null)

  const handleSelectImage = () => {
    fileInputRef?.current?.click()
  }

  const compressImage = (file: File): Promise<File> => {
    const canvas = document.createElement('canvas')
    const img = document.createElement('img')
    const url = URL.createObjectURL(file)

    return new Promise((resolve, reject) => {
      img.onload = function() {
        let width = img.width
        let height = img.height
        const maxHeight = 1024
        const maxWidth = 1024

        if (width > height) {
          if (width > maxWidth) {
            height = Math.round((height *= maxWidth / width))
            width = maxWidth
          }
        } else {
          if (height > maxHeight) {
            width = Math.round((width *= maxHeight / height))
            height = maxHeight
          }
        }
        canvas.width = width
        canvas.height = height

        const ctx = canvas.getContext('2d')
        ctx?.drawImage(img, 0, 0, width, height)

        if (file.type === 'image/jpeg') {
          canvas.toBlob(
            blob => {
              if (blob) {
                resolve(new File([blob], file.name))
              } else {
                reject()
              }
            },
            'image/jpeg',
            0.7
          )
        } else {
          canvas.toBlob(blob => {
            if (blob) {
              resolve(new File([blob], file.name))
            } else {
              reject()
            }
          }, file.type)
        }
      }
      img.onerror = function(err) {
        reject(err)
      }
      img.src = url
    })
  }

  const handleSelectedImage = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const { files, validity } = event.target
    const file = files && files[0]
    if (!validity.valid || !file) {
      return null
    }
    try {
      const compressedFile = await compressImage(file)
      await updateAvatar({ variables: { businessId: business.id, file: compressedFile } })
    } catch (error) {
      console.log({ error })
      enqueueSnackbar(error?.graphQLErrors[0]?.message ?? error.message, {
        variant: 'error',
      })
    }
  }

  return (
    <Box position='relative'>
      <Avatar src={business?.image?.url || undefined} className={classes.avatar}>
        <BusinessIcon />
      </Avatar>
      <Fab
        size='small'
        disabled={loading}
        aria-label='edit'
        className={classes.avatarAction}
        onClick={handleSelectImage}
      >
        {loading ? <CircularProgress size={16} /> : <EditIcon />}
      </Fab>
      <input
        ref={fileInputRef}
        type='file'
        accept='image/*'
        className={classes.hidden}
        onChange={handleSelectedImage}
      />
    </Box>
  )
})

const useStyles = makeStyles(theme => ({
  content: {
    height: '100%',
  },
  avatar: {
    position: 'relative',
    width: 120,
    height: 120,
    maxWidth: '100%',
    overflow: 'auto',
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.grey[200],
  },
  avatarAction: {
    position: 'absolute',
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.primary.main,
    right: 0,
    bottom: 0,
    zIndex: theme.zIndex.speedDial,
    boxShadow: theme.shadows[1],
  },
  hidden: {
    display: 'none',
  },
}))
