import React from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { Box, Link, Chip } from '@material-ui/core'
import { useQuery } from '@apollo/react-hooks'

import { PageHeader, Content } from '../../../components/Layout'
import { IBusiness, IBusinessTransaction } from '../../../graphql/types/businesses'
import { ISalesManager } from '../../../graphql/types/user'
import { FILTERED_BUSINESS_TRANSACTIONS_QUERY } from '../../../graphql/queries/transactions'
import {
  BalanceTypeColumn,
  AmountColumn,
  TypeColumn,
  CreatedAtColumn,
  BalanceTransactionStatus,
} from '../../Businesses/balanceTransactionsColumns'
import { IDataTableColumn, DataTable, EmptyDataMessage } from '../../../components/Data'
import {
  IFilteredBusinessTransactionsData,
  IFilteredBusinessTransactionsVariables,
} from '../../../graphql/types/transactions'
import { Loader } from '../../../components/UI'
import Pagination from '../../../components/Data/pagination/Pagination'
import { ROWS_PER_PAGE } from '../../../core/constants'
import dot from 'dot-object'
import PaymentIcon from '@material-ui/icons/Payment'
import { useTransactionsFilter } from './useTransactionsFilter'
import { IPagination } from '../../../core/interfaces'
import { useTransactionsDownload } from './useTransactionDownload'

interface IProps extends RouteComponentProps<{}> {}

const Transactions: React.FC<IProps> = ({ match }) => {
  const { filter, renderFilter, handleChangeFilter } = useTransactionsFilter()
  const [pagination, setPagination] = React.useState<IPagination>({ offset: 0, limit: ROWS_PER_PAGE[0], page: 0 })
  const [sortBy, setSortBy] = React.useState<{ [key: string]: boolean | undefined }>({ createdAt: true })

  const { renderDownload } = useTransactionsDownload()

  const { data, loading } = useQuery<IFilteredBusinessTransactionsData, IFilteredBusinessTransactionsVariables>(
    FILTERED_BUSINESS_TRANSACTIONS_QUERY,
    {
      variables: {
        filter: {
          businessId: filter.business?.id,
          salesManagerId: filter.manager?.id,
          isOnline: filter.isOnline,
          isManagerBusinesses: filter.isManagerBusinesses,
          startDate: filter.startDate,
          type: filter.type,
          paymentType: filter.paymentType,
          status: filter.status,
          endDate: filter.endDate,
        },
        pagination: {
          limit: pagination.limit,
          offset: pagination.offset,
        },
        sortBy,
      },
      fetchPolicy: 'network-only',
    }
  )

  const transactions = data?.filteredBusinessTransactionsForFranchisee.transactions || []
  const total = data?.filteredBusinessTransactionsForFranchisee.total || 0
  const amount = data?.filteredBusinessTransactionsForFranchisee.amount || 0

  const handleChangePerPage = (limit: number) => {
    setPagination({ ...pagination, offset: pagination.page * limit, limit })
  }
  const handleChangePage = (page: number) => {
    setPagination({ ...pagination, offset: page * pagination.limit, page })
  }

  const columns: IDataTableColumn<IBusinessTransaction>[] = [
    {
      label: 'ID',
      accessor: 'id',
      stickyColumn: false,
      sortKey: 'id',
      onSortBy: (sortBy) => {
        setSortBy(sortBy)
      },
    },
    {
      label: 'Партнер',
      stickyColumn: true,
      accessor: (item) => {
        const business: IBusiness = dot.pick('business', item)
        return (
          <Link
            href='#'
            onClick={(event: { preventDefault: () => void }) => {
              event.preventDefault()
              handleChangeFilter('business')(business)
            }}
          >
            {dot.pick('business.companyName', item)}
          </Link>
        )
      },
    },
    { label: '', accessor: (item) => <BalanceTypeColumn item={item} /> },
    { label: 'Статус', accessor: (item) => <BalanceTransactionStatus item={item} /> },
    {
      label: 'Сумма',
      sortKey: 'amount',
      onSortBy: (sortBy) => {
        setSortBy(sortBy)
      },
      accessor: (item) => (
        <Box textAlign='right'>
          <AmountColumn item={item} currency={item.business?.country.currencySymbol || ''} />{' '}
          {dot.pick('psPaymentId', item) && (
            <Chip
              size='small'
              color={'primary'}
              icon={<PaymentIcon />}
              label={'Онлайн оплата'}
              onClick={() => {
                handleChangeFilter('isOnline')(true)
              }}
            />
          )}
        </Box>
      ),
    },
    {
      label: 'Тип',
      accessor: (item) => <TypeColumn item={item} />,
    },
    {
      label: 'Менеджер',
      accessor: (item) => {
        const salesManager: ISalesManager = dot.pick('business.promocodes.0.promocode.salesManager', item)
        return (
          salesManager && (
            <Link
              href='#'
              onClick={(event: { preventDefault: () => void }) => {
                event.preventDefault()
                handleChangeFilter('manager')(salesManager)
              }}
            >
              {salesManager.user.fullName}
            </Link>
          )
        )
      },
    },
    {
      label: 'Дата',
      sortKey: 'createdAt',
      onSortBy: (sortBy) => {
        setSortBy(sortBy)
      },
      accessor: (item) => <CreatedAtColumn item={item} style={{ whiteSpace: 'nowrap' }} />,
    },
  ]

  return (
    <div>
      <PageHeader
        title={`Статистика по транзакциям`}
        right={
          <Box display='inline-flex'>
            {renderDownload(filter)}
            {renderFilter()}
          </Box>
        }
      />
      {loading && <Loader />}
      {transactions.length > 0 ? (
        <Content noGutter>
          <Pagination
            rowsPerPage={pagination.limit}
            rowsPerPageOptions={ROWS_PER_PAGE}
            onChangeRowsPerPage={handleChangePerPage}
            onChangePage={handleChangePage}
            page={pagination.page}
            count={total}
          />
          <DataTable
            size='small'
            stickyHeader
            topHeight={340}
            data={transactions}
            sortedBy={sortBy}
            columns={columns}
          />
          <Box pt={2}>
            <Box mr={2} display='flex' alignItems='center' justifyContent='flex-end'>
              <Box mr={2} fontWeight={500} fontSize={14}>
                Итого:
              </Box>
              <Box fontWeight={600} fontSize={16} textAlign='right'>
                {amount.toLocaleString()}
              </Box>
            </Box>
            <Pagination
              rowsPerPage={pagination.limit}
              rowsPerPageOptions={ROWS_PER_PAGE}
              onChangeRowsPerPage={handleChangePerPage}
              onChangePage={handleChangePage}
              page={pagination.page}
              count={total}
            />
          </Box>
        </Content>
      ) : (
        <EmptyDataMessage />
      )}
    </div>
  )
}

export default Transactions
