import React from 'react'
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableCellProps,
  Size,
  IconButton,
  Box,
} from '@material-ui/core'
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward'
import ImportExportIcon from '@material-ui/icons/ImportExport'
import { makeStyles } from '@material-ui/core/styles'
import dot from 'dot-object'
import cn from 'classnames'

type AccessorFunctionType<ItemT> = (item: ItemT) => string | React.ReactNode
type DataTableElement = <ItemT extends { [key: string]: any }>(p: IProps<ItemT>) => React.ReactElement<IProps<ItemT>>
type SortByFunctionType = (sortBy: { [key: string]: boolean | undefined }) => void
export interface IDataTableColumn<ItemT> extends TableCellProps {
  label: string
  stickyColumn?: boolean
  accessor: string | AccessorFunctionType<ItemT>
  onSortBy?: SortByFunctionType
  sortKey?: string
}

interface IProps<ItemT> {
  data: ItemT[]
  columns: IDataTableColumn<ItemT>[]
  sortedBy?: { [key: string]: boolean | undefined }
  footer?: React.ReactElement | React.ReactNode | boolean
  stickyHeader?: boolean
  topHeight?: number
  size?: Size
  onClickItem?: (item: ItemT) => void
}

export const DataTable: DataTableElement = ({
  size,
  stickyHeader,
  topHeight = 280,
  data,
  footer,
  columns,
  onClickItem,
  sortedBy,
}) => {
  const classes = useStyles({ stickyHeader, topHeight })
  const [sortBy, setSortBy] = React.useState<{ [key: string]: boolean | undefined }>(sortedBy || {})

  const renderSortButton = (onSortBy: SortByFunctionType, sortKey: string, label: string) => {
    const sortValue = sortBy[sortKey]
    return (
      <Box display='inline-flex'>
        {label}
        <IconButton
          size='small'
          onClick={() => {
            const sortValue = sortBy[sortKey] === undefined ? false : sortBy[sortKey] === false ? true : undefined
            const newSortBy = { ...sortBy, [sortKey]: sortValue }
            setSortBy(newSortBy)
            onSortBy(newSortBy)
          }}
        >
          {sortValue === undefined ? (
            <ImportExportIcon fontSize='small' />
          ) : (
            <ArrowDownwardIcon fontSize='small' className={cn({ [classes.sortDesc]: sortValue })} />
          )}
        </IconButton>
      </Box>
    )
  }

  return (
    <TableContainer className={classes.container}>
      <Table size={size} stickyHeader={stickyHeader} className={classes.table} aria-label='simple table'>
        <TableHead>
          <TableRow>
            {columns.map(({ label, accessor, stickyColumn, className, sortKey, onSortBy, ...cellProps }, index) => (
              <TableCell
                key={`cell-${index}`}
                className={cn({ [classes.stickyHeadCell]: !!stickyColumn }, className || null)}
                {...cellProps}
              >
                {!!onSortBy && sortKey ? renderSortButton(onSortBy, `${sortKey}`, label) : label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((item, index) => (
            <TableRow key={`row-${index}`} hover={!!onClickItem} onClick={() => onClickItem && onClickItem(item)}>
              {columns.map(({ label, accessor, stickyColumn, className, sortKey, onSortBy, ...cellProps }, index) => (
                <TableCell
                  key={`row-cell-${index}`}
                  className={cn({ [classes.stickyCol]: !!stickyColumn }, className || null)}
                  {...cellProps}
                >
                  {typeof accessor === 'function' ? accessor(item) : dot.pick(accessor, item)}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
        {footer}
      </Table>
    </TableContainer>
  )
}

const useStyles = makeStyles(theme => ({
  table: {
    minWidth: 650,
  },
  container: (props: { stickyHeader?: boolean; topHeight: number }) => ({
    maxHeight: props.stickyHeader ? `max(55vh, 100vh - ${props.topHeight || 280}px)` : '100%',
  }),
  stickyHeadCell: {
    position: 'sticky',
    backgroundColor: 'white',
    width: 160,
    minWidth: 160,
    maxWidth: 160,
    left: 0,
    zIndex: 4,
  },
  stickyCol: {
    position: 'sticky',
    backgroundColor: 'white',
    width: 160,
    minWidth: 160,
    maxWidth: 160,
    left: 0,
    zIndex: 3,
    borderRight: '1px solid rgba(224, 224, 224, 1)',
  },
  sortDesc: {
    transform: 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
}))
