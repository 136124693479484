import React from 'react'
import { Typography } from '@material-ui/core'

import { IBusinessTransaction } from '../../../graphql/types/businesses'
import { PromocodeDiscountType } from '../../../core/interfaces'

interface IProps {
  item: IBusinessTransaction
}

export const PromocodeColumn: React.FC<IProps> = ({ item }) => {
  if (!item.promocode) {
    return null
  }

  const discountTypeValue =
    item.promocode.discountType === PromocodeDiscountType.PERCENT ? '%' : item.business?.country.currencySymbol

  return (
    <div>
      <Typography>{item.promocode.title}</Typography>
      <Typography component='p' variant='caption' color='textSecondary'>
        {item.promocode.code} - {item.promocode.discount}
        {discountTypeValue}
      </Typography>
    </div>
  )
}
