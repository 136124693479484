import React, { useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import { IBusinessesFilter } from '../../../graphql/types/businesses'
import { BusinessesFilter } from './BusinessesFilter'

const filterFromQS = (search: string): IBusinessesFilter => {
  const query = new URLSearchParams(search)

  return {
    query: query.get('query') || '',
    isActivated: query.get('isActivated') === null ? undefined : query.get('isActivated') === 'true' ? true : false,
    isVisible: query.get('isVisible') === null ? undefined : query.get('isVisible') === 'true' ? true : false,
  }
}

const filterToQSArray = (filter: IBusinessesFilter): string[] => {
  const arr: string[] = []
  Object.keys(filter).forEach((key) => {
    const value = filter[key as keyof IBusinessesFilter]

    if (value !== undefined && value !== '') {
      arr.push(`${key}=${value}`)
    }
  })

  return arr
}

export const useBusinessesFilter = (path: string = '/businesses') => {
  const history = useHistory()
  const searchString = useLocation().search
  const filter = filterFromQS(searchString)
  const hasFilter = () => filterToQSArray(filter).length > 0
  const [filterVisible, setFilterVisible] = useState<boolean>(hasFilter)

  const toggleFilter = () => {
    setFilterVisible(!filterVisible)

    if (filterVisible === true) {
      history.push(path)
    }
  }

  const handleChangeFilter = (filter: IBusinessesFilter) => {
    const arr = filterToQSArray(filter)

    history.push(`${path}${arr.length > 0 ? '?' + arr.join('&') : ''}`)
  }

  const renderFilter = () => (filterVisible ? <BusinessesFilter filter={filter} onChange={handleChangeFilter} /> : null)

  return {
    filter,
    filterVisible,
    toggleFilter,
    renderFilter,
  }
}
