import React from 'react'
import { Box, Typography } from '@material-ui/core'

import { FranchiseeTransactionStatus } from '../../../core/interfaces'
import { FRANCHISEE_TRANSACTION_STATUS_OPTIONS } from '../../../core/constants'
import { IFranchiseeTransaction } from '../../../graphql/types/franchisee'

interface IProps {
  item: IFranchiseeTransaction
}

export const BalanceTransactionStatus: React.FC<IProps> = ({ item }) => {
  const status = FRANCHISEE_TRANSACTION_STATUS_OPTIONS[item.status]

  return (
    <Box color={status.color}>
      <Typography color='inherit'>{status.label}</Typography>
      {!!item.error && (
        <Typography
          color={item.status === FranchiseeTransactionStatus.INCOMPLETE ? 'inherit' : 'error'}
          variant='caption'
        >
          {!!item.errorCode && `[${item.errorCode}] `} {item.error}
        </Typography>
      )}
    </Box>
  )
}
