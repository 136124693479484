import { grey } from '@material-ui/core/colors'
import { createMuiTheme } from '@material-ui/core/styles'

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#1B6185',
    },
    secondary: {
      main: '#48B1BF',
    },
    action: {
      hoverOpacity: 0.1,
    },
    background: {
      default: 'rgba(18, 67, 93, 0.06)',
    },
  },
  typography: {
    subtitle1: {
      fontWeight: 500,
    },
  },
  overrides: {
    MuiDrawer: {
      paperAnchorDockedLeft: {
        borderRight: 'none',
      },
    },
    MuiDivider: {
      root: {
        backgroundColor: grey[200],
      },
    },
    MuiTableCell: {
      head: {
        borderColor: grey[200],
      },
      body: {
        borderColor: grey[200],
      },
    },
  },
})

export default theme
