import React from 'react'
import { Box, TextField, Typography, Button } from '@material-ui/core'
import { Formik, FormikProps, FormikHelpers } from 'formik'
import { useMutation } from '@apollo/react-hooks'
import { useSnackbar } from 'notistack'

import { Content } from '../../components/Layout'
import { CHANGE_PASSWORD_MUTATION } from '../../graphql/queries/user'
import { LoadableButton } from '../../components/Form'
import { IChangePasswordData, IChangePasswordVariables } from '../../graphql/types/user'
import { changePasswordSchema } from './validation'

interface IPassword {
  oldPassword?: string | null
  newPassword?: string | null
  confirmPassword?: string | null
}

export default function ChangePasswordForm() {
  const [formVisible, setFormVisible] = React.useState<boolean>(false)
  const [changePassword, { loading }] = useMutation<IChangePasswordData, IChangePasswordVariables>(
    CHANGE_PASSWORD_MUTATION
  )

  const initialValues: IPassword = {
    oldPassword: '',
    newPassword: '',
    confirmPassword: '',
  }

  const { enqueueSnackbar } = useSnackbar()

  const handleSubmit = async (values: IPassword, formikHelpers: FormikHelpers<IPassword>) => {
    try {
      await changePassword({
        variables: {
          newPassword: values.newPassword || '',
          oldPassword: values.oldPassword || '',
        },
      })
      formikHelpers.resetForm()
      enqueueSnackbar('Пароль успешно изменен!', { variant: 'success' })
    } catch (error) {
      if (error.response) {
        enqueueSnackbar(error.response.data.error, { variant: 'error' })
      } else {
        enqueueSnackbar(error.message, { variant: 'error' })
      }
    }
  }

  const toggleForm = () => {
    setFormVisible(!formVisible)
  }

  if (!formVisible) {
    return <Button onClick={toggleForm}>Изменить пароль</Button>
  }

  return (
    <div>
      <Box mt={3} mb={2}>
        <Typography variant='h6'>Изменить пароль</Typography>
      </Box>
      <Content>
        <Formik initialValues={initialValues} validationSchema={changePasswordSchema} onSubmit={handleSubmit}>
          {({ values, errors, handleChange, handleSubmit }: FormikProps<IPassword>) => (
            <form onSubmit={handleSubmit}>
              <Box>
                <TextField
                  error={!!errors.oldPassword}
                  helperText={errors.oldPassword}
                  name={'oldPassword'}
                  type='password'
                  label='Старый пароль'
                  value={values.oldPassword}
                  onChange={handleChange}
                  fullWidth
                  margin='normal'
                />
              </Box>
              <Box>
                <TextField
                  error={!!errors.newPassword}
                  name={'newPassword'}
                  type='password'
                  helperText={errors.newPassword}
                  label='Новый пароль'
                  value={values.newPassword}
                  onChange={handleChange}
                  fullWidth
                  margin='normal'
                />
              </Box>
              <Box mb={3}>
                <TextField
                  error={!!errors.confirmPassword}
                  helperText={errors.confirmPassword}
                  type='password'
                  name={'confirmPassword'}
                  label='Подтвердить пароль'
                  value={values.confirmPassword}
                  onChange={handleChange}
                  fullWidth
                  margin='normal'
                />
              </Box>
              <Box textAlign='right'>
                <LoadableButton loading={loading} type='submit' variant='contained' color='primary'>
                  Изменить пароль
                </LoadableButton>
              </Box>
            </form>
          )}
        </Formik>
      </Content>
    </div>
  )
}
