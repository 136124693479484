import React from 'react'
import { CircularProgress } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { grey } from '@material-ui/core/colors'
import cn from 'classnames'

interface IProps {
  full?: boolean
  size?: number
  noGutter?: boolean
}

export const Loader: React.FC<IProps> = ({ full, noGutter, size = 30 }) => {
  const classes = useStyles()

  return (
    <div className={cn(classes.root, { [classes.full]: !!full, [classes.noGutter]: !!noGutter })}>
      <CircularProgress color='inherit' size={size} thickness={4} />
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
    display: 'flex',
    justifyContent: 'center',
    color: grey[600],
  },
  noGutter: {
    padding: 0,
  },
  full: {
    position: 'fixed',
    left: 0,
    top: 0,
    zIndex: theme.zIndex.tooltip,
    alignItems: 'center',
    width: '100%',
    height: '100%',
    backgroundColor: '#fff',
  },
}))
