import { useApolloClient } from '@apollo/client'
import React from 'react'
import useStores from '../../../stores/hooks/useStores'

import { FRANCHISEE_PROFILE } from '../../../graphql/queries/franchisee'
import { IFranchiseeProfileData } from '../../../graphql/types/franchisee'

export const ProfileWatcher = () => {
  const client = useApolloClient()
  const { authStore } = useStores()

  React.useEffect(() => {
    const watcher = client
      .watchQuery<IFranchiseeProfileData, {}>({ query: FRANCHISEE_PROFILE })
      .subscribe(
        ({ data, loading }) => {
          if (!loading) {
            authStore.setFranchisee(data.franchiseeProfile.franchisee)
            authStore.setUser(data.franchiseeProfile.user)
          }
        },
        (error) => {
          console.log({ error })
        }
      )

    return () => {
      watcher.unsubscribe()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return null
}
