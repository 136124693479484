import { useQuery } from '@apollo/react-hooks'

import { BUSINESS_DETAIL_QUERY, BUSINESS_STATS_QUERY } from '../../queries/businesses'
import {
  IBusinessDetailData,
  IBusinessDetailVariables,
  IBusinessStatsData,
  IBusinessStatsVariables,
} from '../../types/businesses'

export const useBusinessDetailWithStatsQuery = (id: number) => {
  const detailResult = useQuery<IBusinessDetailData, IBusinessDetailVariables>(BUSINESS_DETAIL_QUERY, {
    variables: { id },
  })

  const statsResult = useQuery<IBusinessStatsData, IBusinessStatsVariables>(BUSINESS_STATS_QUERY, {
    variables: { id },
  })

  const business = detailResult.data?.businessDetailForFranchisee
  const stats = statsResult.data?.businessStats
  const loading = detailResult.loading || statsResult.loading

  return {
    business,
    stats,
    loading,
  }
}
