import React from 'react'
import { TextField, Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Formik, FormikProps } from 'formik'

import { LoadableButton } from '../../../components/Form'
import { inviteSchema } from './validation'

export interface IInviteFormValues {
  password: string
  confirmPassword: string
}

interface IProps {
  onSubmit: (values: IInviteFormValues) => void
  loading?: boolean
}

export const InviteForm: React.FC<IProps> = ({ onSubmit, loading }) => {
  const classes = useStyles()
  const initialValues: IInviteFormValues = {
    password: '',
    confirmPassword: '',
  }

  const handleFormSubmit = (values: IInviteFormValues) => {
    onSubmit(values)
  }

  return (
    <Formik initialValues={initialValues} validationSchema={inviteSchema} onSubmit={handleFormSubmit}>
      {({ values, errors, handleChange, handleSubmit }: FormikProps<IInviteFormValues>) => (
        <form className={classes.form} onSubmit={handleSubmit}>
          <TextField
            label='Новый пароль'
            name='password'
            value={values.password}
            onChange={handleChange}
            error={!!errors.password}
            helperText={errors.password}
            required
            type='password'
            fullWidth
            margin='normal'
          />
          <TextField
            label='Повторите пароль'
            name='confirmPassword'
            value={values.confirmPassword}
            onChange={handleChange}
            error={!!errors.confirmPassword}
            required
            helperText={errors.confirmPassword}
            type='password'
            fullWidth
            margin='normal'
          />
          <Box mt={3}>
            <LoadableButton loading={loading} color='primary' variant='contained' fullWidth type='submit'>
              Продолжить
            </LoadableButton>
          </Box>
        </form>
      )}
    </Formik>
  )
}

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%',
  },
}))
