import React from 'react'
import { TextField, Box, InputAdornment, IconButton, MenuItem } from '@material-ui/core'
import { Clear as ClearIcon } from '@material-ui/icons'
import { Formik, FormikProps } from 'formik'

import { IBusinessSchedule, IBusiness } from '../../../graphql/types/businesses'
import { Content } from '../../../components/Layout'
import { LoadableButton, PhoneField } from '../../../components/Form'
import { ScheduleForm } from './ScheduleForm'
import { profileSchema } from './validation'

interface IProps {
  business: IBusiness
  onSubmit: (values: IProfileFormValues) => void
  loading?: boolean
}

export interface IProfileFormValues {
  companyName: string
  cashbackPercent: string
  address?: string
  phone?: string
  website?: string
  description?: string
  schedule?: IBusinessSchedule[]
  cityId?: string
}

export const BusinessProfileForm: React.FC<IProps> = ({ business, onSubmit, loading }) => {
  const initialValues: IProfileFormValues = {
    companyName: business.companyName || '',
    cashbackPercent: `${business.cashbackPercent || ''}`,
    address: business.address || undefined,
    phone: business.phone || undefined,
    website: business.website || undefined,
    description: business.description || undefined,
    schedule: business.schedule || undefined,
    cityId: `${business.city?.id || ''}`,
  }

  const handleSubmit = (values: IProfileFormValues) => {
    onSubmit(values)
  }

  return (
    <Formik initialValues={initialValues} validationSchema={profileSchema} onSubmit={handleSubmit}>
      {({ values, errors, submitForm, handleChange, setFieldValue }: FormikProps<IProfileFormValues>) => (
        <div>
          <Content>
            <TextField
              placeholder='Название компании'
              label='Название компании'
              value={values.companyName}
              onChange={handleChange('companyName')}
              error={!!errors.companyName}
              margin='normal'
              fullWidth
              required
              helperText={errors.companyName}
            />
            <TextField
              placeholder='Размер кэшбека'
              label='Размер кэшбека'
              value={`${values.cashbackPercent}`}
              onChange={handleChange('cashbackPercent')}
              error={!!errors.cashbackPercent}
              margin='normal'
              fullWidth
              type='number'
              required
              InputProps={{
                endAdornment: <InputAdornment position='end'>%</InputAdornment>,
              }}
              helperText={errors.cashbackPercent}
            />
            <TextField
              placeholder='Описание'
              label='Описание'
              value={values.description}
              onChange={handleChange('description')}
              multiline
              error={!!errors.description}
              margin='normal'
              fullWidth
            />
          </Content>
          <Box mb={3} />
          <Content>
            <TextField
              placeholder='Город'
              label='Город'
              value={values.cityId}
              onChange={handleChange('cityId')}
              error={!!errors.cityId}
              margin='normal'
              fullWidth
              select
            >
              {business.country.cities && business.country.cities.map(city => (
                <MenuItem key={`city-${city.id}`} value={city.id}>{city.name}</MenuItem>
              ))}
            </TextField>
            <TextField
              placeholder='Адрес'
              label='Адрес'
              value={values.address}
              onChange={handleChange('address')}
              error={!!errors.address}
              margin='normal'
              fullWidth
            />
            <PhoneField
              label='Телефон'
              name='phone'
              value={values.phone}
              onChange={e => setFieldValue('phone', `+${e.target.value.replace(/\D+/g, '')}`)}
              InputProps={{
                endAdornment: !!values.phone && (
                  <InputAdornment position='end'>
                    <IconButton onClick={() => setFieldValue('phone', '')}>
                      <ClearIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              error={!!errors.phone}
              margin='normal'
              fullWidth
              helperText={errors.phone}
            />
            <TextField
              placeholder='Сайт'
              label='Сайт'
              value={values.website}
              onChange={handleChange('website')}
              error={!!errors.website}
              margin='normal'
              fullWidth
            />
            <Box mt={3}>
              <ScheduleForm
                schedule={values.schedule || []}
                onChange={schedule => setFieldValue('schedule', schedule)}
              />
            </Box>
          </Content>
          <Box mt={3}>
            <LoadableButton loading={loading} variant='contained' color='primary' onClick={submitForm}>
              Сохранить
            </LoadableButton>
          </Box>
        </div>
      )}
    </Formik>
  )
}
