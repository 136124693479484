import React from 'react'
import { VisibilityOutlined as VisibleIcon, VisibilityOffOutlined as VisibleOffIcon } from '@material-ui/icons'

import { IBusiness } from '../../../graphql/types/businesses'

interface IProps {
  business: IBusiness
}

export const VisibilityColumn: React.FC<IProps> = ({ business }) => {
  return business.isVisible ? <VisibleIcon color='primary' /> : <VisibleOffIcon color='disabled' />
}
