import React from 'react'
import { Paper } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import cn from 'classnames'

interface IProps {
  noGutter?: boolean
}

export const Content: React.FC<IProps> = ({ children, noGutter }) => {
  const classes = useStyles()

  return (
    <Paper className={cn(!noGutter && classes.root)} elevation={0}>
      {children}
    </Paper>
  )
}

const useStyles = makeStyles(theme => ({
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
}))
