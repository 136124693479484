import gql from 'graphql-tag'

import { FRANCHISE_FRAGMENT } from './franchisee'

export const LOGIN_MUTATION = gql`
  mutation LoginMutation($phone: PhoneNumber!, $password: String!) {
    loginFranchiseeByPassword(phone: $phone, password: $password) {
      user {
        id
        phone
        firstName
        fullName
        email
        roles
      }
      franchisee {
        ...FranchiseeFragment
      }
      token
      refreshToken
    }
  }
  ${FRANCHISE_FRAGMENT}
`

export const RESET_PASSWORD_MUTATION = gql`
  mutation ResetPasswordMutation($password: String!, $token: String!) {
    resetPassword(password: $password, token: $token) {
      user {
        id
        phone
        fullName
        firstName
        lastName
        email
        birthDate
        gender
        ageGroup
        roles
      }
      token
      refreshToken
    }
  }
`

export const REFRESH_TOKEN_MUTATION = gql`
  mutation RefreshTokenMutation {
    refreshTokenFranchisee {
      user {
        id
        phone
        fullName
        firstName
        lastName
        email
        birthDate
        gender
        ageGroup
        roles
      }
      franchisee {
        ...FranchiseeFragment
      }
      token
      refreshToken
    }
  }
  ${FRANCHISE_FRAGMENT}
`
