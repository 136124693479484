import React from 'react'
import { Box, makeStyles, Typography } from '@material-ui/core'
import { AttachMoney as CashIcon, CardGiftcardOutlined as GiftIcon } from '@material-ui/icons'
import { green, red } from '@material-ui/core/colors'

import { IBusiness } from '../../../graphql/types/businesses'

interface IProps {
  business: IBusiness
}

export const BalanceColumn: React.FC<IProps> = ({ business }) => {
  const classes = useStyles()

  return (
    <Box>
      <Box display='flex' alignItems='center'>
        <CashIcon color='disabled' className={classes.icon} />
        <Typography className={business.balance > 0 ? classes.in : classes.out}>{business.balance}</Typography>
      </Box>
      <Box display='flex' alignItems='center'>
        <GiftIcon color='disabled' className={classes.icon} />
        <Typography className={business.bonusBalance > 0 ? classes.in : classes.out}>{business.bonusBalance}</Typography>
      </Box>
    </Box>
  )
}

const useStyles = makeStyles(theme => ({
  icon: {
    width: 16,
    height: 16,
    marginRight: 3,
  },
  in: {
    fontWeight: theme.typography.fontWeightMedium,
    color: green[500],
  },
  out: {
    fontWeight: theme.typography.fontWeightMedium,
    color: red[500],
  },
}))
