import React from 'react'
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, Typography } from '@material-ui/core'
import { Formik, FormikProps } from 'formik'
import * as yup from 'yup'
import { useMutation } from '@apollo/react-hooks'

import { UPDATE_BUSINESS_TRANSACTION_FEE_MUTATION, BUSINESS_DETAIL_QUERY } from '../../../graphql/queries/businesses'
import {
  IUpdateBusinessTransactionFeeData,
  IUpdateBusinessTransactionFeeVariables,
} from '../../../graphql/types/businesses'
import { TransactionFeeType } from '../../../core/interfaces'
import { IBusiness } from '../../../graphql/types/businesses'
import { LoadableButton } from '../../../components/Form'
import { TransactionFeeTypeSelect } from './TransactionFeeTypeSelect'
import { IFranchiseeSettings } from '../../../graphql/types/franchisee'
import useStores from '../../../stores/hooks/useStores'
import { observer } from 'mobx-react'
import { Loader } from '../../../components/UI'

interface IProps {
  business: IBusiness
  open: boolean
  onClose: () => void
}

interface ITransactionFeeFormValues {
  type: TransactionFeeType
  amount: string
  minAmount: string
}

const validationSchemaWithSettings = (settings: IFranchiseeSettings, business: IBusiness) =>
  yup.object().shape({
    type: yup.mixed().oneOf([TransactionFeeType.PERCENT, TransactionFeeType.FIX]).required(),
    amount: yup
      .number()
      .positive()
      .when('type', {
        is: TransactionFeeType.PERCENT,
        then: yup
          .number()
          .min(
            settings.businessCommission.percent.min,
            `Минимум ${settings.businessCommission.percent.min}% - Максимум ${settings.businessCommission.percent.max}%`
          )
          .max(
            settings.businessCommission.percent.max,
            `Минимум ${settings.businessCommission.percent.min}% - Максимум ${settings.businessCommission.percent.max}%`
          ),
      })
      .when('type', {
        is: TransactionFeeType.FIX,
        then: yup
          .number()
          .min(
            settings.businessCommission.fixed.min,
            `Минимум ${settings.businessCommission.fixed.min}${business.country.currencySymbol} - Максимум ${settings.businessCommission.fixed.max}${business.country.currencySymbol}`
          )
          .max(
            settings.businessCommission.fixed.max,
            `Минимум ${settings.businessCommission.fixed.min}${business.country.currencySymbol} - Максимум ${settings.businessCommission.fixed.max}${business.country.currencySymbol}`
          ),
      })
      .required('Введите цифровое значение'),
    minAmount: yup
      .number()
      .when('type', {
        is: TransactionFeeType.PERCENT,
        then: yup
          .number()
          .min(
            settings.businessCommission.percent.min,
            `Минимум ${settings.businessCommission.percent.min}% - Максимум ${settings.businessCommission.percent.max}%`
          )
          .max(
            settings.businessCommission.percent.max,
            `Минимум ${settings.businessCommission.percent.min}% - Максимум ${settings.businessCommission.percent.max}%`
          ),
      })
      .when('type', {
        is: TransactionFeeType.FIX,
        then: yup
          .number()
          .min(
            settings.businessCommission.fixed.min,
            `Минимум ${settings.businessCommission.fixed.min}${business.country.currencySymbol} - Максимум ${settings.businessCommission.fixed.max}${business.country.currencySymbol}`
          )
          .max(
            settings.businessCommission.fixed.max,
            `Минимум ${settings.businessCommission.fixed.min}${business.country.currencySymbol} - Максимум ${settings.businessCommission.fixed.max}${business.country.currencySymbol}`
          ),
      })
      .nullable(),
  })

export const TransactionFeeModal: React.FC<IProps> = observer(({ business, open, onClose }) => {
  const initialValues: ITransactionFeeFormValues = {
    amount: `${business.transactionFee}`,
    minAmount: `${business.minTransactionFee || ''}`,
    type: business.transactionFeeType,
  }

  const [loading, setLoading] = React.useState<boolean>(false)
  const { authStore } = useStores()

  const [updateTransactionFee] = useMutation<IUpdateBusinessTransactionFeeData, IUpdateBusinessTransactionFeeVariables>(
    UPDATE_BUSINESS_TRANSACTION_FEE_MUTATION
  )

  const handleSubmit = async (values: ITransactionFeeFormValues) => {
    try {
      setLoading(true)
      const { data } = await updateTransactionFee({
        variables: {
          businessId: business.id,
          input: {
            amount: parseFloat(values.amount),
            type: values.type,
            minAmount: values.minAmount ? parseFloat(values.minAmount) : undefined,
          },
        },
        refetchQueries: [{ query: BUSINESS_DETAIL_QUERY, variables: { id: business.id } }],
      })

      if (data?.updateBusinessTransactionFee) {
        onClose()
      }
    } catch (error) {
      console.log(error.message)
    } finally {
      setLoading(false)
    }
  }

  const DefaulMinTransactionFee = () => (
    <Typography variant='caption'>
      По умолчанию для всех {business.country.minTransactionFee}
      {business.country.currencySymbol}
    </Typography>
  )

  if (!authStore.franchisee?.settings) {
    return <Loader />
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchemaWithSettings(authStore.franchisee.settings, business)}
      onSubmit={handleSubmit}
    >
      {({ values, errors, setFieldValue, handleChange, submitForm }: FormikProps<ITransactionFeeFormValues>) => (
        <Dialog open={open} maxWidth='sm' fullWidth aria-labelledby='form-dialog-title'>
          <DialogTitle id='form-dialog-title'>Комиссия за транзакцию</DialogTitle>
          <DialogContent>
            <TransactionFeeTypeSelect value={values.type} onChange={(type) => setFieldValue('type', type)} />
            <TextField
              label='Комиссия'
              name='amount'
              value={values.amount}
              onChange={handleChange}
              required
              fullWidth
              helperText={errors.amount}
              margin='normal'
              error={!!errors.amount}
              type='number'
            />
            <TextField
              label={`Минимальная комиссия (${business.country.currencySymbol}) для этого партнера`}
              name='minAmount'
              value={values.minAmount}
              onChange={handleChange}
              fullWidth
              margin='normal'
              error={!!errors.minAmount}
              type='number'
              helperText={<DefaulMinTransactionFee />}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose} color='default'>
              Закрыть
            </Button>
            <LoadableButton loading={loading} color='primary' onClick={submitForm}>
              Применить
            </LoadableButton>
          </DialogActions>
        </Dialog>
      )}
    </Formik>
  )
})
