import React from 'react'
import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import { IBusinessSchedule } from '../../../graphql/types/businesses'
import { MaskedField } from '../../../components/Form'

interface IProps {
  schedule: IBusinessSchedule[]
  onChange: (schedule: IBusinessSchedule[]) => void
}

const weekDays = ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс']

export const ScheduleForm: React.FC<IProps> = ({ schedule, onChange }) => {
  const handleChange = (text: string, index: number) => {
    const other = schedule.filter(s => s.day !== index)

    onChange([...other, { day: index, workingTimes: text }])
  }

  return (
    <div>
      {weekDays.map((day, index) => {
        const item = schedule.find(s => s.day === index)

        return (
          <DayRow
            key={`day-${index}`}
            label={day}
            value={item?.workingTimes}
            onChange={text => handleChange(text, index)}
          />
        )
      })}
    </div>
  )
}

const DayRow: React.FC<{ label: string; value?: string; onChange: (text: string) => void }> = ({
  label,
  value,
  onChange,
}) => {
  const classes = useStyles()

  return (
    <div className={classes.dayRow}>
      <Typography className={classes.dayRowLabel}>{label}</Typography>
      <MaskedField
        mask={'99:99 - 99:99'}
        placeholder='Время работы'
        value={value}
        onChange={e => onChange(e.target.value)}
      />
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  dayRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
  },
  dayRowLabel: {
    width: 40,
  },
  dayRowValue: {},
}))
