import React from 'react'
import { Box, Dialog, DialogTitle, DialogContent } from '@material-ui/core'
import { Alert } from '@material-ui/lab'

import { ICreatePromocodeInput } from '../../../graphql/types/promocodes'
import { useCreatePromocode } from '../../../graphql/hooks/promocodes'
import { PromocodeForm, IPromocodeFormValues } from './PromocodeForm'

interface IProps {
  open: boolean
  onCancel: () => void
}

export const AddPromocodeModal: React.FC<IProps> = ({ open, onCancel }) => {
  const [error, setError] = React.useState<string | undefined>(undefined)
  const { createPromocode, loading } = useCreatePromocode()

  const handleSubmit = async (values: IPromocodeFormValues) => {
    const input: ICreatePromocodeInput = {
      title: values.title,
      description: values.description,
      code: `${values.code}`,
      discount: parseFloat(values.discount),
      discountType: values.discountType,
      startDate: values.startDate,
      endDate: values.endDate,
    }

    const { error } = await createPromocode(input)

    if (error) {
      setError(error)
    } else {
      onCancel()
    }
  }

  return (
    <Dialog open={open} maxWidth='sm' fullWidth>
      <DialogTitle id='form-dialog-title'>Новый промокод</DialogTitle>
      <DialogContent>
        {!!error && (
          <Box mb={3}>
            <Alert severity='error'>{error}</Alert>
          </Box>
        )}
        <Box>
          <PromocodeForm loading={loading} onSubmit={handleSubmit} onCancel={onCancel} />
        </Box>
      </DialogContent>
    </Dialog>
  )
}
