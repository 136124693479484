import React, { ChangeEvent } from 'react'
import { Box, Typography, TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'

import {
  IPromocode,
  IAvailablePromocodesData,
  IAvailablePromocodesVariables,
} from '../../../../graphql/types/promocodes'
import { Loader } from '../../../../components/UI'
import { useQuery } from '@apollo/react-hooks'
import { AVAILABLE_PROMOCODES_QUERY } from '../../../../graphql/queries/promocodes'

interface IProps {
  managerId?: number | null
  value: IPromocode[] | null
  onSelect: (option: IPromocode[] | null) => void
  error?: boolean
}

export const PromocodesAutocomplete: React.FC<IProps> = ({ managerId, value, onSelect, error }) => {
  const [open, setOpen] = React.useState<boolean>(false)
  const { data: { availablePromocodesForFranchisee: promocodes = [] } = {}, loading } = useQuery<
    IAvailablePromocodesData,
    IAvailablePromocodesVariables
  >(AVAILABLE_PROMOCODES_QUERY, { variables: { filter: { managerId } }, fetchPolicy: 'cache-and-network' })

  const renderOption = (option: IPromocode) => (
    <Box width='100%' display='flex' flexDirection='row' justifyContent='space-between'>
      <Typography>
        <Typography display='inline' component='span'>
          {option.title}
        </Typography>
        <Typography display='inline' component='span' color='textSecondary'>
          {' '}
          - {option.code}
        </Typography>
      </Typography>
    </Box>
  )

  const handleChange = (event: ChangeEvent<{}>, option: IPromocode[] | null) => {
    onSelect(option)
  }

  if (loading) {
    return <Loader />
  }

  return (
    <Autocomplete
      open={open}
      onOpen={() => {
        setOpen(true)
      }}
      onClose={() => {
        setOpen(false)
      }}
      value={value || []}
      multiple
      getOptionSelected={(option, value) => option.id === value.id}
      getOptionLabel={(option) => `${option.title} (${option.code})`}
      renderOption={renderOption}
      options={promocodes}
      noOptionsText={'Не найдено'}
      onChange={handleChange}
      renderInput={(params) => (
        <TextField
          {...params}
          label='Промокод'
          error={error}
          margin='normal'
          InputProps={{
            ...params.InputProps,
          }}
        />
      )}
    />
  )
}
