import React from 'react'
import { Dialog, Tabs, Tab, Box } from '@material-ui/core'

import { IBusiness } from '../../../graphql/types/businesses'
import { AddBalanceForm } from './Balance/AddBalanceForm'
import { WithdrawBalanceForm } from './Balance/WithdrawBalanceForm'

interface IProps {
  business: IBusiness
  open: boolean
  onClose: () => void
}

export const BusinessBalanceModal: React.FC<IProps> = ({ business, open, onClose }) => {
  const [selectedTabIndex, setSelectedTabIndex] = React.useState<number>(0)

  const handleChangeTab = (event: React.ChangeEvent<{}>, newValue: number) => {
    setSelectedTabIndex(newValue)
  }

  return (
    <Dialog open={open} maxWidth='sm' fullWidth aria-labelledby='form-dialog-title'>
      <Tabs value={selectedTabIndex} variant='fullWidth' onChange={handleChangeTab}>
        <Tab label='Пополнение' />
        <Tab label='Списание' />
      </Tabs>
      <Box p={3}>
        {selectedTabIndex === 0 && <AddBalanceForm business={business} onClose={onClose} />}
        {selectedTabIndex === 1 && <WithdrawBalanceForm business={business} onClose={onClose} />}
      </Box>
    </Dialog>
  )
}
