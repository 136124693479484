import React from 'react'
import { Box } from '@material-ui/core'

import { PageHeader } from '../../components/Layout'
import { useProfile } from '../../graphql/hooks/franchisee'
import { BalanceHistory } from './components'

const Balance: React.FC = () => {
  const { franchisee } = useProfile()

  if (!franchisee) {
    return null
  }

  return (
    <Box>
      <PageHeader title='Баланс' />
      <BalanceHistory franchisee={franchisee} />
    </Box>
  )
}

export default Balance
