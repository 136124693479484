import React from 'react'
import { Box, IconButton } from '@material-ui/core'
import FirstPageIcon from '@material-ui/icons/FirstPage'
import LastPageIcon from '@material-ui/icons/LastPage'
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import { TablePaginationActionsProps } from '@material-ui/core/TablePagination/TablePaginationActions'

function TableActions(
  props: TablePaginationActionsProps
) {
  const { count, page, rowsPerPage, onChangePage } = props
  function handleFirstPageButtonClick(event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null) {
    onChangePage(event, 0)
  }

  function handleBackButtonClick(event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null) {
    onChangePage(event, page - 1)
  }

  function handleNextButtonClick(event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null) {
    onChangePage(event, page + 1)
  }

  function handleLastPageButtonClick(event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null) {
    onChangePage(event, Math.max(0, Math.round(count / rowsPerPage)))
  }

  return (
    <Box display='flex'>
      <IconButton onClick={handleFirstPageButtonClick} disabled={page === 0} aria-label='First Page'>
        <FirstPageIcon />
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0 || count < rowsPerPage}
        aria-label='Previous Page'
      >
        <KeyboardArrowLeft />
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page === Math.ceil(count / rowsPerPage) - 1 || count < rowsPerPage}
        aria-label='Next Page'
      >
        <KeyboardArrowRight />
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page === Math.ceil(count / rowsPerPage) - 1 || count < rowsPerPage}
        aria-label='Last Page'
      >
        <LastPageIcon />
      </IconButton>
    </Box>
  )
}

export default TableActions
