import React from 'react'
import { AppBar, Toolbar, IconButton, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Menu as MenuIcon } from '@material-ui/icons'

import { logo } from '../../core/images'
import { HeaderMenu } from './HeaderMenu'

interface IProps {
  drawerOpen: boolean
  onClickMenu: () => void
}

export const Header: React.FC<IProps> = ({ drawerOpen, onClickMenu }) => {
  const classes = useStyles()

  return (
    <AppBar position='absolute' color='inherit' elevation={0} className={classes.appBar}>
      <Toolbar disableGutters={!drawerOpen} className={classes.toolbar}>
        <IconButton color='inherit' aria-label='Open drawer' onClick={onClickMenu} className={classes.menuButton}>
          <MenuIcon />
        </IconButton>
        <img src={logo} className={classes.logo} alt='' />
        <Typography component='h1' variant='h6' color='textPrimary' noWrap className={classes.title}>
          PAYDA FRANCHISEE
        </Typography>
        <HeaderMenu />
      </Toolbar>
    </AppBar>
  )
}

const useStyles = makeStyles((theme) => ({
  appBar: {
    backgroundColor: '#fff',
    color: theme.palette.grey[700],
    zIndex: theme.zIndex.drawer + 1,
  },
  toolbar: {
    paddingRight: theme.spacing(1), // keep right padding when drawer closed
    paddingLeft: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      paddingRight: theme.spacing(2), // keep right padding when drawer closed
      paddingLeft: theme.spacing(2),
    },
  },
  menuButton: {
    marginRight: 12,
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  logo: {
    width: 34,
    marginRight: theme.spacing(1) + 2,
  },
  title: {
    flexGrow: 1,
  },
}))
