import gql from 'graphql-tag'

export const ALL_PROMOCODES_QUERY = gql`
  query AllPromocodesQuery {
    allPromocodesForFranchisee {
      id
      title
      description
      code
      discount
      discountType
      startDate
      endDate
      createdAt
      createdBy {
        id
        phone
        fullName
      }
      deactivated
      deactivatedAt
      deactivatedBy {
        id
        phone
        fullName
      }
    }
  }
`

export const AVAILABLE_PROMOCODES_QUERY = gql`
  query AvailablePromocodesQuery($filter: AvailablePromocodeFilterInput) {
    availablePromocodesForFranchisee(filter: $filter) {
      id
      title
      description
      code
      discount
      discountType
      startDate
      endDate
      createdAt
      createdBy {
        id
        phone
        fullName
      }
      deactivated
      deactivatedAt
      deactivatedBy {
        id
        phone
        fullName
      }
    }
  }
`

export const CREATE_PROMOCODE_MUTATION = gql`
  mutation CreatePromocodeMutation($input: CreatePromocodeInput!) {
    createPromocodeByFranchisee(input: $input) {
      id
      title
      description
      code
      discount
      discountType
      startDate
      endDate
      createdAt
      createdBy {
        id
        phone
        fullName
      }
      deactivated
      deactivatedAt
      deactivatedBy {
        id
        phone
        fullName
      }
    }
  }
`

export const UPDATE_PROMOCODE_MUTATION = gql`
  mutation UpdatePromocodeMutation($input: UpdatePromocodeInput!) {
    updatePromocodeByFranchisee(input: $input) {
      id
      title
      description
      code
      discount
      discountType
      startDate
      endDate
      createdAt
      createdBy {
        id
        phone
        fullName
      }
      deactivated
      deactivatedAt
      deactivatedBy {
        id
        phone
        fullName
      }
    }
  }
`
