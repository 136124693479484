import React from 'react'
import { useMutation } from '@apollo/react-hooks'

import { CREATE_PROMOCODE_MUTATION, ALL_PROMOCODES_QUERY } from '../../queries/promocodes'
import { ICreatePromocodeInput, ICreatePromocodeData, ICreatePromocodeVariables } from '../../types/promocodes'

export const useCreatePromocode = () => {
  const [loading, setLoading] = React.useState<boolean>(false)
  const [createPromocodeMutation] = useMutation<ICreatePromocodeData, ICreatePromocodeVariables>(
    CREATE_PROMOCODE_MUTATION
  )

  const createPromocode = async (input: ICreatePromocodeInput): Promise<{ error?: string }> => {
    try {
      setLoading(true)

      await createPromocodeMutation({
        variables: { input },
        refetchQueries: [
          {
            query: ALL_PROMOCODES_QUERY,
          },
        ],
      })

      return {}
    } catch (error) {
      return { error: error.message }
    } finally {
      setLoading(false)
    }
  }

  return {
    createPromocode,
    loading,
  }
}
