import React from 'react'
import { Box, Button, TextField, Typography } from '@material-ui/core'
import { Formik, FormikProps } from 'formik'

import { ROLES_LABELS } from '../../../../core/constants'
import { IPromocode } from '../../../../graphql/types/promocodes'
import { PhoneField, LoadableButton } from '../../../../components/Form'
import { UserRole } from '../../../../core/interfaces'
import { PromocodesAutocomplete } from './PromocodesAutocomplete'
import { managerSchema } from './validation'

interface IProps {
  loading: boolean
  initialData?: IManagerFormValues | null
  onSubmit: (values: IManagerFormValues) => void
  onCancel: () => void
}

export interface IManagerFormValues {
  firstName: string
  phone: string
  email: string
  promocodes: IPromocode[] | null
}

export const ManagerForm: React.FC<IProps> = ({ loading, initialData, onSubmit, onCancel }) => {
  const initialValues: IManagerFormValues = initialData
    ? initialData
    : {
        firstName: '',
        phone: '',
        email: '',
        promocodes: [],
      }

  const handleSubmit = (values: IManagerFormValues) => {
    onSubmit(values)
  }

  return (
    <Formik initialValues={initialValues} validationSchema={managerSchema} onSubmit={handleSubmit}>
      {({ values, errors, setFieldValue, handleChange, submitForm }: FormikProps<IManagerFormValues>) => (
        <div>
          <Box mb={1}>
            <Typography variant='body2' color='textSecondary'>
              Роль
            </Typography>
            <Typography>{ROLES_LABELS[UserRole.SALES_MANAGER]}</Typography>
          </Box>
          <TextField
            label='Имя'
            name='firstName'
            value={values.firstName}
            onChange={handleChange}
            required
            fullWidth
            margin='normal'
            error={!!errors.firstName}
          />
          <PhoneField
            label='Телефон'
            name='phone'
            value={values.phone}
            onChange={e => setFieldValue('phone', `+${e.target.value.replace(/\D+/g, '')}`)}
            error={!!errors.phone}
            required
            fullWidth
            margin='normal'
          />
          <TextField
            label='Email'
            name='email'
            value={values.email}
            onChange={handleChange}
            required
            fullWidth
            margin='normal'
            error={!!errors.email}
          />
          <Box>
            <PromocodesAutocomplete
              value={values.promocodes}
              onSelect={p => setFieldValue('promocodes', p)}
              error={!!errors.promocodes}
            />
          </Box>
          <Box pt={4} pb={1} display='flex' flexDirection='row' justifyContent='space-between'>
            <Button onClick={onCancel}>Отмена</Button>
            <LoadableButton loading={loading} color='primary' variant='contained' onClick={submitForm}>
              Сохранить
            </LoadableButton>
          </Box>
        </div>
      )}
    </Formik>
  )
}
