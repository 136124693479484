import React, { ChangeEvent } from 'react'
import { Box, Typography, TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { useQuery } from '@apollo/react-hooks'

import {
  IAllBusinessesData,
  IAllBusinessesVariables,
  IBusiness,
  IBusinessShortest,
} from '../../../graphql/types/businesses'
import { ALL_BUSINESSES_QUERY } from '../../../graphql/queries/businesses'

interface IProps {
  managerId?: number | null
  value: IBusinessShortest | IBusiness | null
  isVisible?: boolean
  isActivated?: boolean
  onSelect: (option: IBusinessShortest | IBusiness | null) => void
  error?: boolean
}

export const BusinessAutocomplete: React.FC<IProps> = ({ value, isVisible, isActivated, onSelect, error }) => {
  const [open, setOpen] = React.useState<boolean>(false)
  const [query, setQuery] = React.useState<string>('')

  const { data, loading } = useQuery<IAllBusinessesData, IAllBusinessesVariables>(ALL_BUSINESSES_QUERY, {
    variables: {
      filter: { query, isVisible, isActivated },
    },
    fetchPolicy: 'cache-and-network',
  })

  const businesses = data?.businessesForFranchisee?.businesses || []

  const renderOption = (option: IBusinessShortest | IBusiness) => (
    <Box width='100%' display='flex' flexDirection='row' justifyContent='space-between'>
      <Typography>
        <Typography display='inline' component='span'>
          {option.companyName}
        </Typography>
        <Typography display='inline' component='span' color='textSecondary'>
          {' '}
          - {option.id}
        </Typography>
      </Typography>
    </Box>
  )

  const handleChange = (event: ChangeEvent<{}>, option: IBusinessShortest | IBusiness | null) => {
    onSelect(option)
  }

  return (
    <Autocomplete
      open={open}
      onOpen={() => {
        setOpen(true)
      }}
      onClose={() => {
        setOpen(false)
      }}
      value={value}
      getOptionSelected={(option, value) => option.id === value.id}
      getOptionLabel={(option) => `${option.companyName}`}
      renderOption={renderOption}
      options={businesses}
      loading={loading}
      noOptionsText={'Не найдено'}
      onChange={handleChange}
      onInputChange={(event, newInputValue) => {
        console.log({ newInputValue })
        setQuery(newInputValue)
      }}
      fullWidth
      renderInput={(params) => (
        <TextField
          {...params}
          label='Партнер'
          error={error}
          margin='normal'
          fullWidth
          InputProps={{
            ...params.InputProps,
          }}
        />
      )}
    />
  )
}
