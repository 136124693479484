import React from 'react'
import { Box } from '@material-ui/core'
import { CheckCircleOutlined as ActivatedIcon, HighlightOffOutlined as OffIcon } from '@material-ui/icons'
import { green } from '@material-ui/core/colors'

import { IBusiness } from '../../../graphql/types/businesses'

interface IProps {
  business: IBusiness
}

export const ActivationColumn: React.FC<IProps> = ({ business }) => {
  return business.isActivated ? (
    <Box color={green[500]}>
      <ActivatedIcon color='inherit' />
    </Box>
  ) : <OffIcon color='disabled' />
}
