import React from 'react'
import { ListItem, ListItemAvatar, ListItemText, ListItemSecondaryAction } from '@material-ui/core'

interface IProps {
  title: string
  secondaryText?: string | React.ReactElement
  avatar?: React.ReactElement
  actions?: React.ReactElement
  onClick?: () => void
}

export const DataListItem: React.FC<IProps> = ({ title, secondaryText, avatar, actions, onClick }) => {
  return (
    <ListItem button onClick={onClick}>
      {avatar && <ListItemAvatar>{avatar}</ListItemAvatar>}
      <ListItemText primary={title} secondary={secondaryText} />
      {actions && <ListItemSecondaryAction>{actions}</ListItemSecondaryAction>}
    </ListItem>
  )
}
