import React from 'react'
import { Avatar, makeStyles } from '@material-ui/core'
import { AttachMoney as CashIcon, CardGiftcardOutlined as GiftIcon } from '@material-ui/icons'
import { lightBlue, green, grey } from '@material-ui/core/colors'
import cn from 'classnames'

import { IBusinessTransaction } from '../../../graphql/types/businesses'
import { BusinessBalanceType, BusinessTransactionStatus } from '../../../core/interfaces'

interface IProps {
  item: IBusinessTransaction
}

export const BalanceTypeColumn: React.FC<IProps> = ({ item }) => {
  const classes = useStyles()
  const isDefault = item.balanceType === BusinessBalanceType.DEFAULT
  const isInactive = [
    BusinessTransactionStatus.PENDING,
    BusinessTransactionStatus.FAILURE,
    BusinessTransactionStatus.INCOMPLETE,
  ].includes(item.status)
  const avatarClass = isInactive ? classes.inactive : isDefault ? classes.default : classes.bonus

  return (
    <Avatar className={cn(classes.avatar, avatarClass)}>
      {isDefault ? <CashIcon className={classes.icon} /> : <GiftIcon className={classes.icon} />}
    </Avatar>
  )
}

const useStyles = makeStyles(theme => ({
  avatar: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
  inactive: {
    color: '#fff',
    backgroundColor: grey[400],
  },
  default: {
    color: '#fff',
    backgroundColor: green[200],
  },
  bonus: {
    color: '#fff',
    backgroundColor: lightBlue[400],
  },
  icon: {
    fontSize: 18,
  },
}))
