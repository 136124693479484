import gql from 'graphql-tag'

export const USER_FRAGMENT = gql`
  fragment UserFragment on User {
    id
    phone
    fullName
    firstName
    lastName
    email
    birthDate
    gender
    ageGroup
    roles
  }
`

export const CHANGE_PASSWORD_MUTATION = gql`
  mutation ChangePasswordMutation($oldPassword: String!, $newPassword: String!) {
    changePassword(oldPassword: $oldPassword, newPassword: $newPassword)
  }
`

export const UPDATE_USER_PROFILE_MUTATION = gql`
  mutation UpdateUserProfileMutation($input: UserProfileInput!) {
    updateUserProfile(input: $input) {
      ...UserFragment
    }
  }
  ${USER_FRAGMENT}
`
