import React, { Fragment } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { PageHeader, Content } from '../../../components/Layout'
import { useQuery } from '@apollo/react-hooks'
import { ISalesManagerByIdVariables, ISalesManagerByIdData } from '../../../graphql/types/user'
import { SALES_MANAGER_BY_ID_QUERY } from '../../../graphql/queries/users'
import { Loader } from '../../../components/UI'
import { EmptyDataMessage, DataList } from '../../../components/Data'
import { Box, Typography, Chip, makeStyles, Avatar } from '@material-ui/core'
import { Storefront as BusinessIcon } from '@material-ui/icons'
import { IBusiness } from '../../../graphql/types/businesses'
import { plural } from '../../../utils/helpers'
import { TransactionFeeType } from '../../../core/interfaces'
import { ManagerInfo } from './components/ManagerInfo'

interface IProps extends RouteComponentProps<{ id: string }> {}

const SalesManager: React.FC<IProps> = ({
  match: {
    params: { id },
  },
}) => {
  const { data, loading } = useQuery<ISalesManagerByIdData, ISalesManagerByIdVariables>(SALES_MANAGER_BY_ID_QUERY, {
    variables: { id: parseInt(id, 10) },
  })
  const classes = useStyles()

  const renderAvatar = (item: IBusiness) => (
    <Avatar src={item.image?.url || undefined} className={classes.avatar}>
      <BusinessIcon />
    </Avatar>
  )

  const renderTitle = (item: IBusiness) => {
    return (
      <Fragment>
        <Typography display='inline'>{item.companyName}</Typography>
        {!item.isVisible && <Chip className={classes.chip} size='small' label='невидимый' />}
      </Fragment>
    )
  }

  const renderSecondaryText = (item: IBusiness) => {
    const suffix = item.transactionFeeType === TransactionFeeType.FIX ? ` ${item.country.currencySymbol}` : '%'

    return `${item.transactionFee}${suffix}`
  }

  if (loading) {
    return <Loader />
  }
  if (!data || !data.salesManagerByIdForFranchisee) {
    return <EmptyDataMessage />
  }

  const manager = data.salesManagerByIdForFranchisee

  return (
    <div>
      <PageHeader parentPath='/sales/managers' title={`Менеджер ${manager.user.fullName}`} />
      <Box mb={2}>
        <ManagerInfo manager={manager} />
      </Box>
      <Box mb={1}>
        <Typography>
          Список подключенных партнеров ({manager.businessCount || 0}{' '}
          {plural(['партнер', 'партнера', 'партнеров'], manager.businessCount || 0)})
        </Typography>
      </Box>
      <Box>
        {data?.allBusinesses && (
          <Fragment>
            {data.allBusinesses.length === 0 && !loading ? (
              <EmptyDataMessage />
            ) : (
              <Content noGutter>
                <DataList
                  data={data.allBusinesses}
                  title={renderTitle}
                  renderAvatar={renderAvatar}
                  secondaryText={renderSecondaryText}
                />
              </Content>
            )}
          </Fragment>
        )}
      </Box>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  avatar: {
    color: '#fff',
    backgroundColor: theme.palette.primary.main,
  },
  chip: {
    marginLeft: theme.spacing(1),
  },
}))

export default SalesManager
