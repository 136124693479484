import { useMutation } from '@apollo/react-hooks'

import { SWITCH_BUSINESS_VISIBILITY_MUTATION, ALL_BUSINESSES_QUERY } from '../../queries/businesses'
import { ISwitchBusinessVisibilityData, ISwitchBusinessVisibilityVariables } from '../../types/businesses'

export const useSwitchBusinessVisibility = () => {
  const [switchBusinessVisibility] = useMutation<ISwitchBusinessVisibilityData, ISwitchBusinessVisibilityVariables>(
    SWITCH_BUSINESS_VISIBILITY_MUTATION
  )

  return async (businessId: number) => {
    await switchBusinessVisibility({
      variables: { businessId },
      refetchQueries: [
        {
          query: ALL_BUSINESSES_QUERY,
        },
      ],
    })
  }
}
