import { useQuery } from '@apollo/client'

import { FRANCHISEE_PROFILE } from '../../queries/franchisee'
import { IFranchiseeProfileData } from '../../types/franchisee'

export const useProfile = () => {
  const { data } = useQuery<IFranchiseeProfileData, {}>(FRANCHISEE_PROFILE)

  const user = data?.franchiseeProfile.user
  const franchisee = data?.franchiseeProfile.franchisee

  return {
    user,
    franchisee,
  }
}
