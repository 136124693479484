import React from 'react'
import { Typography } from '@material-ui/core'

import { IFranchiseeTransaction } from '../../../graphql/types/franchisee'

interface IProps {
  item: IFranchiseeTransaction
  currency: string
}

export const BusinessColumn: React.FC<IProps> = ({ item, currency }) => {
  if (!item.businessTransaction || !item.businessTransaction.business) {
    return null
  }

  return (
    <div>
      <Typography>{item.businessTransaction.business.companyName}</Typography>
      <Typography variant='caption' color='textSecondary'>
        Пополнение: {item.businessTransaction.amount} {currency}
      </Typography>
    </div>
  )
}
