import React from 'react'
import { Button, Box } from '@material-ui/core'

import { LIST_LIMIT } from '../../core/constants'
import { Loader } from '../UI'

interface IProps {
  loading: boolean
  count: number
  total: number
  onClick: () => void
}

export const FetchMoreButton: React.FC<IProps> = ({ loading, count, total, onClick }) => {
  if (loading) {
    return <Loader />
  }

  if (count >= total) {
    return null
  }

  const diff = total - count
  const moreCount = diff > LIST_LIMIT ? LIST_LIMIT : diff

  return (
    <Box p={1} textAlign='center'>
      <Button onClick={onClick}>{`Загрузить еще ${moreCount}`}</Button>
    </Box>
  )
}
