import React from 'react'
import {
  Box,
  CardContent,
  Card,
  makeStyles,
  Typography,
  FormControlLabel,
  Checkbox,
  IconButton,
  ClickAwayListener,
} from '@material-ui/core'
import { Close as CloseIcon, FilterList as FilterIcon } from '@material-ui/icons'
import cn from 'classnames'

import { BusinessAutocomplete } from '../components/BusinessAutocomplete'
import { IBusiness, IBusinessShortest } from '../../../graphql/types/businesses'

interface IFilter {
  business: IBusinessShortest | IBusiness | null
  isVisible: boolean
}

type Value<T, K extends keyof T> = T[K]

export const useBusinessTransactionFeesByDayFilter = () => {
  const classes = useStyles()
  const initialFilter: IFilter = {
    business: null,
    isVisible: true,
  }

  const [filter, setFilter] = React.useState<IFilter>(initialFilter)
  const [filterVisible, setFilterVisible] = React.useState<boolean>(false)

  const toggleFilter = () => {
    setFilterVisible(!filterVisible)
  }

  const closeFilter = () => {
    setFilterVisible(false)
  }

  const handleChange = <K extends keyof IFilter>(field: K) => (value: Value<IFilter, K>) => {
    setFilter({ ...filter, [field]: value })
  }

  const renderFilter = () => (
    <ClickAwayListener onClickAway={closeFilter}>
      <Box position='relative'>
        <IconButton onClick={toggleFilter}>
          <FilterIcon />
        </IconButton>
        <Card className={cn(classes.container, { [classes.containerClosed]: !filterVisible })} square>
          <CardContent>
            <div className={classes.appBarSpacer} />
            <Box>
              <Box display='flex' alignItems='center' justifyContent='space-between'>
                <Typography variant='subtitle2'>Фильтр</Typography>
                <IconButton size='small' onClick={toggleFilter}>
                  <CloseIcon />
                </IconButton>
              </Box>
              <Box>
                <BusinessAutocomplete
                  value={filter.business}
                  isVisible={filter.isVisible}
                  isActivated
                  onSelect={handleChange('business')}
                />
              </Box>
              <Box>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={filter.isVisible}
                      onChange={event => {
                        handleChange('isVisible')(event.target.checked)
                      }}
                      name='isVisible'
                    />
                  }
                  label='Только видимые'
                />
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Box>
    </ClickAwayListener>
  )

  return {
    filter,
    renderFilter,
    handleChangeFilter: handleChange,
  }
}

const useStyles = makeStyles(theme => ({
  container: {
    width: 260,
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    overflowY: 'auto',
    overflowX: 'hidden',
    zIndex: theme.zIndex.drawer,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  containerClosed: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: 0,
  },
  appBarSpacer: theme.mixins.toolbar,
}))
