import React from 'react'
import { List } from '@material-ui/core'

import { DataListItem } from './DataListItem'

type TitleFunctionType<ItemT> = (item: ItemT) => string | React.ReactNode
type DataListElement = <ItemT extends { [key: string]: any }>(p: IProps<ItemT>) => React.ReactElement<IProps<ItemT>>

interface IProps<ItemT> {
  data: ItemT[]
  title: string | TitleFunctionType<ItemT>
  secondaryText?: (item: ItemT) => string | React.ReactElement | undefined
  renderAvatar?: (item: ItemT) => React.ReactElement
  renderActions?: (item: ItemT) => React.ReactElement
  onClickItem?: (item: ItemT) => void
}

export const DataList: DataListElement = ({ data, title, secondaryText, renderAvatar, renderActions, onClickItem }) => {
  return (
    <div>
      <List>
        {data.map((item, index) => {
          const itemTitle = typeof title === 'function' ? title(item) : item[title]
          const avatar = renderAvatar && renderAvatar(item)

          return (
            <DataListItem
              key={`item-${index}`}
              title={itemTitle}
              secondaryText={secondaryText && secondaryText(item)}
              avatar={avatar}
              actions={renderActions && renderActions(item)}
              onClick={() => onClickItem && onClickItem(item)}
            />
          )
        })}
      </List>
    </div>
  )
}
