import { green, grey, red } from '@material-ui/core/colors'
import {
  BusinessTransactionType,
  TransactionFeeType,
  ISelectItem,
  UserRole,
  BusinessTransactionStatus,
  PaymentType,
  FranchiseeTransactionStatus,
  FranchiseeTransactionType,
} from './interfaces'

export const DRAWER_WIDTH = 240

export const LIST_LIMIT = 20

export const PHONE_REGEXP = /^((\+\d{1,3}(-| )?\(?\d\)?(-| )?\d{1,5})|(\(?\d{2,6}\)?))(-| )?(\d{3,4})(-| )?(\d{4})(( x| ext)\d{1,5}){0,1}$/
export const PHONE_MASK_DEFAULT = '+7 (999) 999-99-99'

export const DATE_TIME_FORMAT = 'DD-MM-YYYY HH:mm'
export const DATE_FORMAT = 'DD-MM-YYYY'

export const BUSINESS_TRANSACTION_TYPE_LABELS: { [key: string]: string } = {
  [BusinessTransactionType.REPLENISHMENT]: 'Пополнение',
  [BusinessTransactionType.TRANSACTION_FEE]: 'Комиссия за транзакцию',
  [BusinessTransactionType.WELCOME_BONUS]: 'Welcome bonus',
  [BusinessTransactionType.REGISTRATION_FEE]: 'Оплата за регистрацию',
  [BusinessTransactionType.WITHDRAW_EXPIRED_BALANCE]: 'Списание просроченного Баланса',
  [BusinessTransactionType.OTHER]: 'Другое',
}

export const PAYMENT_TYPE_LABELS: { [key: string]: string } = {
  [PaymentType.CASH]: 'Наличный платеж',
  [PaymentType.CASHLESS]: 'Безналичный платеж',
}

export const TRANSACTION_FEE_TYPES_MAP: ISelectItem<TransactionFeeType>[] = [
  { value: TransactionFeeType.PERCENT, label: 'Процент' },
  { value: TransactionFeeType.FIX, label: 'Фиксировано' },
]

export const ROLES_LABELS: { [key: string]: string } = {
  [UserRole.SALES_SUPERVISOR]: 'Руководитель отдела продаж',
  [UserRole.SALES_MANAGER]: 'Менеджер по продажам',
}

export const CREATABLE_ROLES_MAP: ISelectItem<UserRole>[] = [
  { value: UserRole.SALES_SUPERVISOR, label: ROLES_LABELS[UserRole.SALES_SUPERVISOR] },
]

export const ROWS_PER_PAGE = [15, 30, 50]

export const STATUS_OPTIONS: { [key: string]: { label: string; color: string } } = {
  [BusinessTransactionStatus.SUCCESS]: { label: 'Успешно', color: green[400] },
  [BusinessTransactionStatus.PENDING]: { label: 'В обработке', color: grey[400] },
  [BusinessTransactionStatus.FAILURE]: { label: 'Не успешно', color: red[400] },
  [BusinessTransactionStatus.INCOMPLETE]: { label: 'Незавершенный', color: grey[500] },
}

export const FRANCHISEE_TRANSACTION_STATUS_OPTIONS: { [key: string]: { label: string; color: string } } = {
  [FranchiseeTransactionStatus.SUCCESS]: { label: 'Успешно', color: green[400] },
  [FranchiseeTransactionStatus.PENDING]: { label: 'В обработке', color: grey[400] },
  [FranchiseeTransactionStatus.FAILURE]: { label: 'Не успешно', color: red[400] },
  [FranchiseeTransactionStatus.INCOMPLETE]: { label: 'Незавершенный', color: grey[500] },
}

export const FRANCHISEE_TRANSACTION_TYPE_LABELS: { [key: string]: string } = {
  [FranchiseeTransactionType.REPLENISHMENT]: 'Пополнение',
  [FranchiseeTransactionType.COMMISSION]: 'Комиссия',
}
