import { Box, Chip, ChipProps, fade, makeStyles, Paper, Popover } from '@material-ui/core'
import moment from 'moment'
import React from 'react'
import { PromocodeDiscountType } from '../../../../core/interfaces'
import { IPromocode } from '../../../../graphql/types/promocodes'

const useStyles = makeStyles(theme => ({
  root: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  deactivatedBox: {
    backgroundColor: fade(theme.palette.error.main, 0.07),
    borderColor: fade(theme.palette.error.main, 0.14),
  },
}))

interface IProps extends ChipProps {
  promocode: IPromocode
}

export const PromocodeChip: React.FC<IProps> = ({ promocode, disabled, ...props }) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null)
  const classes = useStyles()

  const handleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? `popover-${promocode.id}` : undefined

  return (
    <>
      <Chip
        className={classes.root}
        aria-describedby={id}
        label={`${promocode.title} (${promocode.code})`}
        {...props}
        color={disabled ? 'default' : 'primary'}
        clickable
        onClick={handleClick}
      />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Box p={2}>
          <Box display='flex' mb={1}>
            <Box mr={1}>Скидка: </Box>
            <Box fontWeight='500'>
              {promocode.discount}
              {promocode.discountType === PromocodeDiscountType.PERCENT ? '%' : ''}
            </Box>
          </Box>
          <Box>Период действия</Box>
          <Box fontWeight='500'>
            {moment(promocode.startDate).format('DD.MM.YYYY')}-{moment(promocode.endDate).format('DD.MM.YYYY')}
          </Box>

          {promocode.deactivated && (
            <Paper variant='outlined' className={classes.deactivatedBox}>
              <Box p={1}>Промокод деактивирован: {moment(promocode.deactivatedAt).format('DD.MM.YYYY')}</Box>
            </Paper>
          )}
        </Box>
      </Popover>
    </>
  )
}
