import React from 'react'
import { Table, TableBody, TablePagination, TableRow } from '@material-ui/core'
import TableActions from './TableActions'

interface IProps {
  rowsPerPageOptions: number[]
  count: number
  rowsPerPage: number
  page: number
  labelRowsPerPage?: string
  onChangePage: (page: number) => void
  onChangeRowsPerPage: (rowsPerPage: number) => void
}

const Pagination: React.FC<IProps> = ({
  rowsPerPageOptions,
  count,
  rowsPerPage,
  page,
  onChangePage,
  onChangeRowsPerPage,
  labelRowsPerPage = '',
}) => {
  const handleChangePerPage: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement> = event => {
    onChangeRowsPerPage && onChangeRowsPerPage(parseInt(event.target.value, 10))
  }
  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
    onChangePage && onChangePage(page)
  }

  return (
    <Table>
      <TableBody>
        <TableRow>
          <TablePagination
            size='small'
            rowsPerPageOptions={rowsPerPageOptions}
            count={count}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangePerPage}
            labelRowsPerPage=''
            ActionsComponent={TableActions}
          />
        </TableRow>
      </TableBody>
    </Table>
  )
}

export default Pagination
