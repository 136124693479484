import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'
import { BrokenImageOutlined } from '@material-ui/icons'

const PageNotFound: React.FC = () => {
  const classes = useStyles()

  return (
    <div className={classes.main}>
      <BrokenImageOutlined className={classes.icon} />
      <Typography variant='h5' className={classes.text} gutterBottom>
        Страница не найдена
      </Typography>
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  main: {
    display: 'flex',
    flexDirection: 'column',
    height: 'calc(100% - 100px)',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
  },
  icon: {
    fontSize: '7rem',
    color: '#ccc',
  },
  text: {
    color: '#ccc',
    textAlign: 'center',
  },
}))

export default PageNotFound
