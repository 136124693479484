import React, { Fragment } from 'react'
import { Typography } from '@material-ui/core'

import { IBusiness } from '../../../graphql/types/businesses'
import { TransactionFeeType } from '../../../core/interfaces'

interface IProps {
  business: IBusiness
}

export const TransactionFeeColumn: React.FC<IProps> = ({ business }) => {
  const renderValue = () => {
    const suffix = business.transactionFeeType === TransactionFeeType.FIX ? ` ${business.country.currencySymbol}` : '%'

    return `${business.transactionFee}${suffix}`
  }

  return (
    <Fragment>
      <Typography>{renderValue()}</Typography>
      {!!business.minTransactionFee && (
        <Typography component='span' variant='caption' color='textSecondary'>
          {`Мин ${business.minTransactionFee}${business.country.currencySymbol}`}
        </Typography>
      )}
    </Fragment>
  )
}
