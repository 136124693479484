import * as yup from 'yup'

import { PromocodeDiscountType } from '../../../core/interfaces'

export const promocodeSchema = yup.object().shape({
  title: yup.string().required(),
  description: yup.string(),
  code: yup.string().required(),
  discountType: yup
    .mixed()
    .oneOf([PromocodeDiscountType.PERCENT, PromocodeDiscountType.FIX])
    .required(),
  discount: yup
    .number()
    .moreThan(0)
    .when('discountType', {
      is: PromocodeDiscountType.PERCENT,
      then: yup.number().max(100),
    })
    .required(),
})

export const updatePromocodeSchema = yup.object().shape({
  title: yup.string().required(),
  description: yup.string(),
  discountType: yup
    .mixed()
    .oneOf([PromocodeDiscountType.PERCENT, PromocodeDiscountType.FIX])
    .required(),
  discount: yup
    .number()
    .moreThan(0)
    .when('discountType', {
      is: PromocodeDiscountType.PERCENT,
      then: yup.number().max(100),
    })
    .required(),
})
