import React from 'react'
import { TextField, TextFieldProps } from '@material-ui/core'
import InputMask from 'react-input-mask'

interface IProps {
  mask: string
}

export const MaskedField: React.FC<IProps & TextFieldProps> = ({ mask, value, onChange, ...props }) => {
  const maskValue = typeof value === 'string' ? value : ''

  return (
    <InputMask mask={mask} value={maskValue} onChange={onChange}>
      {(inputProps: object) => <TextField {...props} {...inputProps} />}
    </InputMask>
  )
}
